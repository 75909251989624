import {flow, types} from "mobx-state-tree"

import ApiService from "../shared/api/ApiService";


const Pharmacy = types.model("Pharmacy", {
    id: types.maybeNull(types.integer),
    name: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    coords: types.maybeNull(types.array(types.number))
})

const PharmacyStore = types.model("PharmacyStore", {
    active: types.safeReference(Pharmacy),
    pharmacies: types.array(Pharmacy),
    isLoading: types.maybe(types.boolean),
    total: types.maybe(types.integer),
}).actions(self => ({
    selectPharmaciesList: flow(function* (params) {
        self.isLoading = true;
        const res = yield ApiService.get("pharmacies", params);
        self.pharmacies = res.data.content === "" ? [] : res.data.content;
        self.total = res.data.totalElements;
        self.isLoading = false;
    }),
    selectOrganization(id) {
        self.active = id;
    },
    getPharmacy: flow(function* (id) {
        const pharmacy = yield ApiService.get(`pharmacies/${id}`);
        return pharmacy.data;
    }),
    save: flow(function* (payload) {
        const pharmacy = yield ApiService.post(`pharmacies`, payload);
        return pharmacy.data;
    })
}));

export default PharmacyStore;