import React, {useState} from 'react';

import {Panel} from "primereact/panel";

import ButtonProp from "../ButtonProp";
import LabeledSearchInput from "../LabeledSearchInput";


function OneRowItemEditor(props) {
    const {onSave, onCancel} = props
    //const grid = {display: "grid", gridTemplateColumns: "150px 1fr", gridRowGap: "0.5rem"}
    const [name, setName] = useState("");
    const [ru_name, setRuName] = useState("");
    const [en_name, setEnName] = useState("");

    const onSaveButtonClicked = () => {
        onSave(name, ru_name, en_name)
    }

    return (
        <Panel  style={{ margin: "1rem 0", padding: "1rem 0 !important"}}>
            <div style={{display: "flex", flexFlow: "row nowrap", alignItems: "center", columnGap: "2rem"}}>
                <div style={{display: "flex", flexFlow: "row nowrap", alignItems: "center", columnGap: "0.5rem"}}>
                    <LabeledSearchInput
                        label="Наименование латынь"
                        value={name} autoFocus={true} onChange={(e) => {
                        setName(e.target.value)
                    }}/>
                    <LabeledSearchInput
                        label="Наименование русский"
                        value={ru_name} onChange={(e) => {
                        setRuName(e.target.value)
                    }}/>
                    <LabeledSearchInput
                        label="Наименование английский"
                        value={en_name} onChange={(e) => {
                        setEnName(e.target.value)
                    }}/>
                </div>
                <div style={{display: "flex", flexFlow: "row nowrap", alignItems: "center", columnGap: "0.5rem"}}>
                    <ButtonProp icon="pi pi-check" onClick={onSaveButtonClicked} label="" severity="success"/>
                    <ButtonProp icon="pi pi-times" onClick={onCancel} label="" severity="danger"/>
                </div>
            </div>
        </Panel>);
}

export default OneRowItemEditor;