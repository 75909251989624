import React, {createContext} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";

import './index.scss';
import App from './app/App';
import {store} from "./app/model/AppState";


export const StoreContext = createContext(store);

ReactDOM.render(
    <React.StrictMode>
        <StoreContext.Provider value={store}>
            <Router>
                <App/>
            </Router>
        </StoreContext.Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

export {CenteredItemStyled} from "./shared/ui/CenteredItems.styled";
export {FullCenteredItemStyled} from "./shared/ui/CenteredItems.styled";