import React from 'react';
import {Switch} from "react-router-dom";
import {observer} from "mobx-react-lite";

// import ToolBar from "./ToolBar";
import LeftMenu from "../../../shared/ui/LeftMenu";
import useStore from "../../../hooks/useStore";
import Patients from "./Patients";
import {propisi} from "../../../app/config/global";
import PatientCard from "./PatientCard";
import RestrictedRoute from "../../../entyties/User/lib/RestrictedRoute";
import configData from "../../../app/config/config.json";
import Prescriptions from "./Prescriptions";


function DoctorUI(props) {
    const {doctorUI} = useStore();

    const menuContent = [
        {
            to: "/doctor",
            title: "Сводка",
            icon: "pi-th-large",
            subMenu: [],
            deep: false
        },
        {
            to: "/doctor/patients",
            title: "Пациенты",
            icon: "pi-users",
            subMenu: [],
            deep: true
        },
        {
            to: "/doctor/prescriptions",
            title: "Назначения",
            icon: "pi-id-card",
            subMenu: [],
            deep: false
        },
        {
            to: "",
            title: "Справочники",
            icon: "pi-book",
            deep: false,
            subMenu: [
                {
                    to: "",
                    title: "Спарвочник ЭЛП"
                },
                {
                    to: "",
                    title: "Справочник гомеопатических ЭЛП"
                },
                {
                    to: "",
                    title: "Справочник аптек"
                },
                {
                    to: "",
                    title: "Натуропатия"
                },
            ]
        },
        {
            to: "/",
            title: "Исследования",
            icon: "pi-share-alt",
            deep: false,
            subMenu: []
        },
        {
            to: "/",
            title: "Аналитика",
            icon: "pi-chart-bar",
            deep: false,
            subMenu: []
        },
    ];

    return (
        <div className="layout">
            <LeftMenu menuContent={menuContent} />
            <div className="layout__container">
                <div className="layout__content">
                    <Switch>
                        <RestrictedRoute path="/doctor/prescriptions" exact level={propisi.DOCTOR_ROLE}>
                            <Prescriptions store={doctorUI}></Prescriptions>
                        </RestrictedRoute>
                        <RestrictedRoute path="/doctor/patients" exact level={propisi.DOCTOR_ROLE}>
                            <Patients store={doctorUI}></Patients>
                        </RestrictedRoute>
                        <RestrictedRoute path="/doctor/patients/:id" exact level={propisi.DOCTOR_ROLE}>
                            <PatientCard store={doctorUI}></PatientCard>
                        </RestrictedRoute>
                    </Switch>
                </div>
                <div className="layout__footer">
                    <div className="ver">{configData.VERSION}</div>
                </div>
            </div>
        </div>
    );
}

export default observer(DoctorUI);
