import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {observer} from "mobx-react-lite";

import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Paginator} from "primereact/paginator";
import {Dialog} from "primereact/dialog";

import "../../../shared/scss/Resizer.css"
import {AppContext} from "../../../app/AppContext";
import {BlockUI} from "../../../shared/ui/BlockUI";
import {ClearButtonOutlined} from "../../../shared/ui/Styles";
import useStore from "../../../hooks/useStore";
import Header from "../../../components/header";


function Orgs(props) {
    const history = useHistory();
    const { organizations } = useStore();
    const [selectedOrg, setSelectedOrg] = useState(undefined);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    })
    const context = useContext(AppContext)

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            organizations.selectOrganizationsList(lazyParams);
        }
        return () => {
            isMounted = false
        }
    }, [lazyParams])

    const onChangePage = (e) => {
        setLazyParams({...lazyParams, first: e.first, rows: e.rows, page: e.page})
    }

    const deleteTemplate = (rowData, options) => {
        return <ClearButtonOutlined
            style={{width: "20px", height: "20px"}}
            type="button"
            icon='pi pi-trash'
            severity="danger"
            className="p-button-sm p-button-text"
            onClick={() => {
                setSelectedOrg(rowData);
                setShowDeleteDialog(true)
            }}
        />;
    };

    const deleteDialogFooterContent = (
        <div>
            <Button label="Да" icon="pi pi-check" severity="danger" onClick={() => {
                context.apiService.deleteMedicalOrg(selectedOrg).then(() => {
                    // setItems(items.filter(i => i.id !== selectedOrg.id))
                    setSelectedOrg(undefined)
                })
                setShowDeleteDialog(false)
            }}/>
            <Button label="Нет" icon="pi pi-check" onClick={() => setShowDeleteDialog(false)} autoFocus/>
        </div>
    );

    return (<>
        {showDeleteDialog && <Dialog
            header="ВНИМАНИЕ"
            visible={showDeleteDialog}
            onHide={() => {
                setShowDeleteDialog(false)
            }}
            footer={deleteDialogFooterContent}
        >
            <div>
                Удалить медучреждение:
            </div>
            <div className={"mt-2"}>
                <strong>{selectedOrg?.organization?.name}?</strong>
            </div>
        </Dialog>}
        <div>
            <Header title={"Медучреждения"} controlButtons = {[{
                label: "Добавить",
                action: () => history.push("/admin/org/0"),
                visible: true
            }]} />
            <BlockUI blocking={organizations.isLoading}>
                <DataTable
                    className="page-datatable"
                    dataKey="id"
                    value={organizations.organizations}
                    selection={selectedOrg}
                    selectionMode="single"
                    onRowClick={(e) => {
                        history.push(`/admin/org/${e.data.id}`)
                    }}
                >
                    <Column header="Наименование" field="name"/>
                    <Column header="Телефон" field="phone"/>
                    <Column header="Емайл" field="email"/>
                    <Column style={{width: "30px"}} body={deleteTemplate}></Column>
                </DataTable>
                <Paginator
                    alwaysShow={false}
                    style={{justifyContent: "flex-end"}}
                    rows={lazyParams.rows}
                    first={lazyParams.first}
                    totalRecords={organizations.total}
                    onPageChange={onChangePage}
                />
            </BlockUI>
        </div>
    </>);
}

export default observer(Orgs);
