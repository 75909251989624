import React, {useEffect, useRef, useState} from 'react';
import {useParams, useHistory} from "react-router-dom";
import {Controller, useForm, useFormState} from "react-hook-form";
import {observer} from "mobx-react-lite";

import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {Panel} from "primereact/panel";
import {Toast} from "primereact/toast";
import {Checkbox} from "primereact/checkbox";

import {Role as RoleModel} from "../model/UserStore";
import useStore from "../../../hooks/useStore";
import Header from "../../../components/header";
import {Dropdown} from "primereact/dropdown";
import ButtonProp from "../../../shared/ui/ButtonProp";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {ClearButtonOutlined} from "../../../shared/ui/Styles";



function Role() {
    let {id} = useParams();
    const history = useHistory();
    const {users} = useStore();

    const [role, setRole] = useState(null)
    const [permissions, setPermissions] = useState(null)
    const [selectedPermission, setSelectedPermission] = useState(null)
    const [addPermissionDisabled, setAddPermissionDisabled] = useState(true)
    const {
        control,
        handleSubmit,
        reset,
        watch,
        formState: {errors},
        setValue,
        getValues
    } = useForm(RoleModel)

    const userForm = useRef(null);
    const toast = useRef(null);

    const inputWrapperStyle = "flex flex-column gap-2 mb-2"
    const rolePermissions = watch("permission")

    useEffect(() => {
        async function fetchData() {
            if (parseInt(id) === 0)
                setRole(RoleModel);
            else {
                await users.getRole(id);
                await users.permissionsList();
                setRole(users.role)
                reset({...users.role});
            }
        }
        fetchData();
    }, []);

    const showMsg = (msg) => {
        toast.current.show({severity: 'info', detail: msg, life: 15000});
    }

    const onSubmit = (roleData) => {
        users.saveRole(roleData).then(res => {
            if (res.status === 200) {
                if (parseInt(id) === 0) {
                    setRole(res.data);
                    history.push(`/admin/users/${id}`)
                    showMsg("Роль создана!");
                }
                else
                    showMsg("Роль сохранена!");
            }

        })
    }

    const addPermission = (permission) => {
        if (!rolePermissions.find(i => i.id === role.id)) {
            rolePermissions.push(permission)
            setValue("permissions", rolePermissions, {shouldDirty: true})
            setAddPermissionDisabled(true)
        }
    }

    const changePermission = (e) => {
        setSelectedPermission(e.value)
        if (rolePermissions) {
            setAddPermissionDisabled(rolePermissions.find(r => {
                return r.id === e.value
            }) !== undefined)
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (<div>
            <ClearButtonOutlined
                style={{width: "20px", height: "20px"}}
                className="ml-4 p-button-text"
                type="button"
                onClick={() => {
                    let permissions = getValues().permission.filter(i => i.id !== rowData.id)
                    setValue("permission", permissions, {shouldDirty: true})
                }}
            />
        </div>)
    }

    const checkBoxBodyTemplate = (rowData) => {
        return (
            <Checkbox
                onChange={() => {
                    let permissions = getValues().permission.map(i => {
                        if(i.id === rowData.id)
                            i.edit = !i.edit;
                        return i;
                    });
                    setValue("permission", permissions, {shouldDirty: true});
                }}
                checked={rowData.edit} />)
    }

    return (<>
        {role && <div className="page">
            <Toast ref={toast} position="top-right"/>
            <Header
                title={role.description}
                backLink={() => history.push("/admin/users/roles")}
                controlButtons={[
                    {
                        label: parseInt(id) === 0 ? "Создать" : "Сохранить",
                        action: () => userForm.current.requestSubmit(),
                        visible: true
                    },
                ]}/>
            <form ref={userForm} onSubmit={handleSubmit(onSubmit)} style={{width: "100%"}} className="p-fluid">
                <Panel className="page__panel page-panel">
                    <div className="page-panel__header">Информация о роли</div>
                    <div className={inputWrapperStyle}>
                        <label htmlFor="login">Название</label>
                        <Controller
                            name="name"
                            control={control}
                            rules={{required: 'Название обязателено к заполнению!'}}
                            render={({field, fieldState}) => (
                                <InputText
                                    value={field.value || ""}
                                    onChange={field.onChange}
                                    className={classNames({
                                        'p-invalid': fieldState.invalid,
                                        "p-input-text": true
                                    })}
                                    style={{width: "100%"}}
                                />
                            )}
                        />
                    </div>
                    <div className={inputWrapperStyle}>
                        <label htmlFor="login">Описание</label>
                        <Controller
                            name="description"
                            control={control}
                            rules={{required: 'Описание обязателено к заполнению!'}}
                            render={({field, fieldState}) => (
                                <InputText
                                    value={field.value || ""}
                                    onChange={field.onChange}
                                    className={classNames({
                                        'p-invalid': fieldState.invalid,
                                        "p-input-text": true
                                    })}
                                    style={{width: "100%"}}
                                />
                            )}
                        />
                    </div>
                </Panel>
                <Panel className="page__panel page-panel">
                    <div className="page-panel__header">Модули и разрешения</div>
                    <div>
                        <div className="mb-4">
                            <Controller
                                name="permission"
                                control={control}
                                render={({field}) => (
                                    <DataTable
                                        key="id"
                                        style={{width: "100%"}}
                                        value={field.value}
                                        emptyMessage="Разрешения не назначены"
                                    >
                                        <Column header="Название" field={"permission.name"}/>
                                        <Column header="Редактирование" body={checkBoxBodyTemplate} field={"edit"}/>
                                        <Column body={actionBodyTemplate} style={{width: "5rem"}}/>
                                    </DataTable>
                                )}/>
                        </div>
                        <div className="flex flex-row justify-content-between align-items-center">
                            <Dropdown
                                key="id"
                                optionLabel="name"
                                className="mr-2 w-full"
                                style={{flex: 6}}
                                value={selectedPermission}
                                options={users.permissions}
                                placeholder={"Выберите модуль"}
                                onChange={changePermission}
                            />
                            <ButtonProp
                                style={{flex: 1}}
                                label="Добавить"
                                icon="pi pi-plus"
                                disabled={addPermissionDisabled || !selectedPermission}
                                onClick={(e) => {
                                    e.preventDefault();
                                    addPermission(permissions.find(i => i.id === selectedPermission))
                                }}
                            />
                        </div>
                    </div>
                </Panel>
            </form>
        </div>}
    </>)
}

export default observer(Role);