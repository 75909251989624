import React, {useEffect, useState} from 'react';
import {DataTable} from "primereact/datatable";
import {Paginator} from "primereact/paginator";
import {Column} from "primereact/column";
import "primeflex/primeflex.css";
import {useMkb10Store} from "../../model/Mkb10Store";
import {observer} from "mobx-react-lite";
import {BlockUI} from "../../../../shared/ui/BlockUI";
import {FilterMatchMode} from "primereact/api";
import Header from "../../../../components/header";

function Mkb10(props) {

    const store = useMkb10Store()
    const [params, setParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    })

    const [filters, setFilters] = useState({
        description: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        code: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });

    useEffect(()=>{
        let isMounted = true
        const loadData = () =>{
            if (isMounted)
                store.fetch({...params})
        }
        loadData()
        return ()=>{isMounted=false}
    },[store, params])

    const onChangePage = (e)=>{
        setParams({...params, first:e.first, rows:e.rows, page: e.page })
    }

    const ifNull = (value, def) =>{
        return value ? value : def
    }
    const onFilter = (e) =>{
        setFilters(e.filters)
        setParams({
            ...params,
            code:ifNull(e.filters.code.value,""),
            description:ifNull(e.filters.description.value,"")
        })
    }

    return (
        <div className="page">
            <Header title={"МКБ10"} />
            <BlockUI blocking={store.isLoading}>
                <DataTable
                    value={store.items}
                    filters={filters}
                    filterDisplay="row"
                    selection={store.selected}
                    selectionMode="single"
                    onSelectionChange={e => {
                        if (e.value) store.setSelected(e.value);
                    }}
                    onFilter={onFilter}
                >
                    <Column filter style={{ width: '20%' }} header="Код" field="code" />
                    <Column filter header="Наименование" field="description" />
                </DataTable>
                <Paginator
                    alwaysShow={false}
                    style={{justifyContent: "flex-end"}}
                    rows={params.rows}
                    first={params.first}
                    totalRecords={store.total}
                    onPageChange={onChangePage}
                />
            </BlockUI>
        </div>
    );
}

export default observer(Mkb10);
