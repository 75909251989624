import React, {useContext, useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {observer} from "mobx-react-lite";

import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {Panel} from "primereact/panel";
import {Toast} from "primereact/toast";

import ItemsList from "../../../../shared/ui/ItemsList";
import {AppContext} from "../../../../app/AppContext";
import {InputTextarea} from "primereact/inputtextarea";
import ListItemEditor from "../../../../shared/ui/ListItemEditor";
import RpItemsEditor from "./RpItemsEditor";
import EmptyItem from "../../../../shared/ui/EmptyItem";
import NumberEditor from "../../../../shared/ui/NumberEditor";
import AreasList from "../../../Area/ui/AreasList";
import ChipsList from "../../../../shared/ui/ChipsList";
import Mkb10List from "../../../Mkb10/ui/Mkb10List/Mkb10List";
import useStore from "../../../../hooks/useStore";
import Header from "../../../../components/header";

function Rp(props) {
    let {id} = useParams();
    const history = useHistory();
    const {rps} = useStore();
    const defaultValues = {
        name: "",
        mds: "",
        action: "",
        items: []
    }

    const inputWrapperStyle = "flex flex-column gap-2 mb-3"
    const labelStyle = "text-base font-medium";
    const {readOnly} = props
    const {
        control,
        handleSubmit,
        reset,
        watch,
        formState: {errors},
        setValue,
        getValues
    } = useForm({defaultValues: undefined});
    const [rp, setRp] = useState({});

    const userForm = useRef(null);
    const toast = useRef(null);

    const [displayLecFormList, setDisplayLecFormList] = useState(false);
    const [displayRpTypeList, setDisplayRpTypeList] = useState(false);
    const [displayATHList, setDisplayATHList] = useState(false);
    const [displayMKBList, setDisplayMKBList] = useState(false);
    const [showAreasDialog, setShowAreasDialog] = useState(false);

    const context = useContext(AppContext)
    const itemsService = context.apiService

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const onChangePrice = (e) => {
        if (typeof e.value === 'string' || e.value instanceof String) {
            setValue("price", Number(e.value), {shouldDirty: true})
        } else
            setValue("price", e.value, {shouldDirty: true})
    }

    const onAddArea = (data) => {
        let a = getValues().areas
        if (!a.find(i => i.id === data.id)) {
            a.push(data)
            setValue("areas", a, {shouldDirty: true})
        }
        setShowAreasDialog(false)
    }

    const onRemoveArea = (e) => {
        let a = getValues().areas.filter((i) => {
            return i.id !== e.id
        })
        setValue("areas", a, {shouldDirty: true})
    }

    const onAddType = (data) => {
        let a = getValues().type
        if (!a.find(i => i.id === data.id)) {
            a.push(data)
            setValue("type", a, {shouldDirty: true})
        }
        setDisplayRpTypeList(false)
    }
    const onRemoveType = (e) => {
        let a = getValues().type.filter((i) => {
            return i.id !== e.id
        })
        setValue("type", a, {shouldDirty: true})
    }

    const onAddAth = (data) => {
        let a = getValues().ath
        if (!a.find(i => i.id === data.id)) {
            a.push(data)
            setValue("ath", a, {shouldDirty: true})
        }
        setDisplayATHList(false)

    }
    const onRemoveAth = (e) => {
        let a = getValues().ath.filter((i) => {
            return i.id !== e.id
        })
        setValue("ath", a, {shouldDirty: true})
    }

    const onAddMKB = (data) => {
        let a = getValues().mkb10
        if (!a.find(i => i.id === data.id)) {
            a.push(data)
            setValue("mkb10", a, {shouldDirty: true})
        }
        setDisplayMKBList(false)

    }
    const onRemoveMKB = (e) => {
        let a = getValues().mkb10.filter((i) => {
            return i.id !== e.id
        })
        setValue("mkb10", a, {shouldDirty: true})
    }

    const onSubmit = (payload) => {
        // pharmacies.save(payload).then(data => {
        //     if (id == 0) {
        //         id = data.id;
        //         setPharmacy(data);
        //         history.push(`/admin/pharmacy/${id}`)
        //         showMsg("Создано!");
        //     }
        //     else
        //         showMsg("Сохранено!");
        // })
    }

    useEffect(() => {
        if (id == 0)
            setRp(defaultValues);
        else {
            rps.getRp(id).then(o => {
                console.log(o);
                reset(o);
                setRp(o);
            });
        }
    }, []);

    if (!rp) return (
        <div>
            <EmptyItem label="Выберите пропись для просмотра" height={"400px"}/>
        </div>
    )

    return (
        <>
            <Toast ref={toast} position="top-right"/>
            <AreasList
                visible={showAreasDialog}
                onHide={() => {
                    setShowAreasDialog(false)
                }}
                onSelectItem={onAddArea}
            />
            <ItemsList
                header="Анатомо-терапевтическая химическая классификация"
                visible={displayATHList}
                onHide={() => {
                    setDisplayATHList(false)
                }}
                getData={itemsService.getAth.bind(itemsService)}
                onAccept={onAddAth}
            />
            <Mkb10List
                visible={displayMKBList}
                onHide={() => {
                    setDisplayMKBList(false)
                }}
                onSelectItem={onAddMKB}
            />
            <ItemsList
                header="Формы выпуска"
                visible={displayLecFormList}
                onHide={() => setDisplayLecFormList(false)}
                getData={itemsService.getRpLecForms.bind(itemsService)}
                onAccept={(e) => {
                    setValue("lecForm", e, {shouldDirty: true})
                    setDisplayLecFormList(false)
                }}
            />
            <ItemsList
                header="Тип"
                visible={displayRpTypeList}
                onHide={() => setDisplayRpTypeList(false)}
                getData={itemsService.getRpTypeList.bind(itemsService)}
                onAccept={onAddType}
            />
            <Header
                title={id != 0 ? rp.name : "Создать "}
                backLink={() => history.push("/admin/rp")}
                controlButtons={[
                    {
                        label: id == 0 ? "Создать" : "Сохранить",
                        action: () => userForm.current.requestSubmit(),
                        visible: true
                    }
                ]}
            />
            <form ref={userForm} onSubmit={handleSubmit(onSubmit)}>
                <Panel className="page__panel page-panel">
                    <div className={inputWrapperStyle}>
                        <label className={labelStyle} htmlFor="name">Наименование:</label>
                        <Controller
                            name="name"
                            control={control}
                            rules={{required: 'Нименование обязателено к заполнению'}}
                            render={({field, fieldState}) => (
                                <InputText
                                    value={field.value || ""}
                                    onChange={field.onChange}
                                    autoComplete="off"
                                    className={classNames({'p-invalid': fieldState.invalid})}
                                    style={{width: "100%"}}
                                    required={true}
                                    readOnly={readOnly}
                                />)
                            }
                        />
                        {getFormErrorMessage("name")}
                    </div>
                    <div className={inputWrapperStyle}>
                        <label className={labelStyle} htmlFor="areas">Область:</label>
                        <Controller
                            name="areas"
                            control={control}
                            render={({field}) => (
                                <ChipsList
                                    readOnly={readOnly}
                                    values={field.value}
                                    onRemoveItem={onRemoveArea}
                                    onAddItem={() => setShowAreasDialog(true)}
                                />
                            )}
                        />
                    </div>

                    <div className={inputWrapperStyle}>
                        <label className={labelStyle} htmlFor="type">Тип действия:</label>
                        <Controller
                            name="type"
                            control={control}
                            render={({field}) => (
                                <ChipsList
                                    readOnly={readOnly}
                                    values={field.value}
                                    onRemoveItem={onRemoveType}
                                    onAddItem={() => setDisplayRpTypeList(true)}
                                />
                            )}
                        />
                    </div>

                    <div className={inputWrapperStyle}>
                        <label className={labelStyle} htmlFor="ath">ATX:</label>
                        <Controller
                            name="ath"
                            control={control}
                            render={({field}) => (
                                <ChipsList
                                    readOnly={readOnly}
                                    values={field.value}
                                    onRemoveItem={onRemoveAth}
                                    onAddItem={() => setDisplayATHList(true)}
                                />
                            )}
                        />
                    </div>

                    <div className={inputWrapperStyle}>
                        <label className={labelStyle} htmlFor="mkb10">МКБ10:</label>
                        <Controller
                            name="mkb10"
                            control={control}
                            render={({field}) => {
                                return (
                                    <ChipsList
                                        readOnly={readOnly}
                                        field="code"
                                        values={field.value}
                                        onRemoveItem={onRemoveMKB}
                                        onAddItem={() => setDisplayMKBList(true)}
                                    />
                                )
                            }}
                        />
                    </div>

                    <div className={inputWrapperStyle}>
                        <label className={labelStyle} htmlFor="lecForm">Форма выпуска</label>
                        <Controller
                            name="lecForm"
                            control={control}
                            render={({field}) => (
                                <ListItemEditor
                                    value={field.value?.name}
                                    onSelect={() => {
                                        setDisplayLecFormList(true)
                                    }}
                                    onClear={() => {
                                        setValue("lecForm", undefined, {shouldDirty: true})
                                    }}
                                    readOnly={readOnly}
                                />
                            )}
                        />
                    </div>
                    <div className={inputWrapperStyle}>
                        <label className={labelStyle} htmlFor="lecForm">Rp.:</label>
                        <RpItemsEditor
                            style={{width: "100%"}}
                            control={control}
                            watch={watch}
                            setValue={setValue}
                            readOnly={readOnly}
                        />
                    </div>
                    <div className={inputWrapperStyle}>
                        <label className={labelStyle} htmlFor="mds">M.D.S.:</label>
                        <Controller
                            name="mds"
                            control={control}
                            rules={{required: 'M.D.S обязателено к заполнению'}}
                            render={({field, fieldState}) => {
                                return <InputTextarea
                                    //{...field}
                                    value={field.value || ""}
                                    onChange={field.onChange}
                                    modules={{toolbar: false}}
                                    autoComplete="off"
                                    className={classNames({'p-invalid': fieldState.invalid})}
                                    style={{width: "100%"}}
                                    required={true}
                                    autoResize
                                    readOnly={readOnly}
                                />

                            }}
                        />
                        {getFormErrorMessage("mds")}
                    </div>
                    <div className={inputWrapperStyle}>
                        <label className={labelStyle} htmlFor="action">Действие и показания:</label>
                        <Controller
                            name="action"
                            control={control}
                            rules={{required: 'Действие и показания обязателено к заполнению'}}
                            render={({field, fieldState}) => (
                                <InputTextarea
                                    value={field.value || ""}
                                    onChange={field.onChange}
                                    autoComplete="off"
                                    className={classNames({'p-invalid': fieldState.invalid})}
                                    style={{width: "100%"}}
                                    autoResize
                                    readOnly={readOnly}
                                />)
                            }
                        />
                        {getFormErrorMessage("action")}
                    </div>

                    <div className={inputWrapperStyle}>
                        <label className={labelStyle} htmlFor="recipe">Способ приготовления:</label>
                        <Controller
                            name="recipe"
                            control={control}
                            render={({field, fieldState}) => (
                                <InputTextarea
                                    {...field}
                                    autoComplete="off"
                                    className={classNames({'p-invalid': fieldState.invalid})}
                                    style={{width: "100%"}}
                                    autoResize
                                    readOnly={readOnly}
                                />)
                            }
                        />
                        {getFormErrorMessage("recipe")}
                    </div>

                    <div className={inputWrapperStyle}>
                        <label className={labelStyle} htmlFor="price">Цена</label>
                        <Controller
                            name="price"
                            control={control}
                            render={({field, fieldState}) => (
                                <NumberEditor
                                    value={field.value}
                                    onValueChange={onChangePrice}
                                    allowedDecimalSeparators={[".", ","]}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    suffix=" p"
                                />
                            )}
                        />
                    </div>
                </Panel>
            </form>
        </>
    );
}

export default observer(Rp);
