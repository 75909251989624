import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {Map, Placemark, SearchControl} from '@pbe/react-yandex-maps';

import {Toast} from "primereact/toast";
import {Panel} from "primereact/panel";

import styled from "styled-components";

import Header from "../../../components/header";
import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import useStore from "../../../hooks/useStore";


const FieldsGrid = styled.div`
    display: grid;
    grid-template-columns:150px 1fr;
    grid-row-gap: 0.5rem;
`
const FullWidthInputText = styled(InputText)`
    width: 100%
`

function Organization(props) {
    let {id} = useParams();
    const history = useHistory();
    const defaultValues = {
        id: undefined,
        name: "",
        address: "",
        phone: "",
        coords: undefined
    }
    const {organizations} = useStore();
    const [org, setOrg] = useState(null);
    const {
        control,
        handleSubmit,
        reset,
        setValue,
    } = useForm({defaultValues})
    const placer = useRef();
    const searchControl = useRef();
    const toast = useRef(null);
    const userForm = useRef(null);

    const showMsg = (msg) => {
        toast.current.show({severity: 'info', detail: msg, life: 15000});
    }

    useEffect(() => {
        if (id == 0)
            setOrg(defaultValues);
        else {
            organizations.getOrganization(id).then(o => {
                reset(o);
                setOrg(o);
            });
        }
    }, []);

    const onSubmit = (payload) => {
        organizations.save(payload).then(data => {
            if (id == 0) {
                id = data.id;
                setOrg(data);
                history.push(`/admin/org/${id}`)
                showMsg("Создано!");
            }
            else
                showMsg("Сохранено!");
        })
    }

    // if (!org) return (
    //     <div>
    //         <EmptyItem label="Выберите организацию для просмотра" height={"400px"}/>
    //     </div>
    // )
    return (
        <>
            {org && <div className="page">
                <Toast ref={toast} position="top-right"/>
                <Header
                    title={id != 0 ? org.name : "Создать "}
                    backLink={() => history.push("/admin/org")}
                    controlButtons = {[
                        {
                            label: id == 0 ? "Создать" : "Сохранить",
                            action: () => userForm.current.requestSubmit(),
                            visible: true
                        }
                    ]}
                />
                <form ref={userForm} onSubmit={handleSubmit(onSubmit)}>
                    <Panel className="page__panel page-panel">
                        <div className="page-panel__header">Данные</div>
                        <div>
                            <FieldsGrid>
                                <div>Название</div>
                                <div>
                                    <Controller
                                        name="name"
                                        control={control}
                                        rules={{required: 'Наименование обязателено к заполнению'}}
                                        render={({field, fieldState}) => (
                                            <FullWidthInputText
                                                autoComplete="Off"
                                                {...field}
                                                className={classNames({'p-invalid': fieldState.invalid})}
                                            />
                                        )}
                                    />
                                </div>
                                <div>Адрес</div>
                                <div>
                                    <Controller
                                        name="address"
                                        control={control}
                                        rules={{required: 'Адрес обязателено к заполнению'}}
                                        render={({field, fieldState}) => (
                                            <FullWidthInputText
                                                autoComplete="Off"
                                                {...field}
                                                className={classNames({'p-invalid': fieldState.invalid})}
                                            />
                                        )}
                                    />
                                </div>
                                <div>Телефон</div>
                                <div>
                                    <Controller
                                        name="phone"
                                        control={control}
                                        rules={{required: 'Телефон обязателено к заполнению'}}
                                        render={({field, fieldState}) => (
                                            <FullWidthInputText
                                                autoComplete="off"
                                                {...field}
                                                className={classNames({'p-invalid': fieldState.invalid})}
                                            />
                                        )}
                                    />
                                </div>
                                <div>Email</div>
                                <div>
                                    <Controller
                                        name="email"
                                        control={control}
                                        rules={{required: 'Email обязателено к заполнению'}}
                                        render={({field, fieldState}) => (
                                            <FullWidthInputText
                                                autoComplete="off"
                                                value={field.value || ""}
                                                onChange={field.onChange}
                                                className={classNames({'p-invalid': fieldState.invalid})}
                                            />
                                        )}
                                    />
                                </div>
                                <div>Координаты</div>
                                <div>
                                    <Controller
                                        name="coords"
                                        control={control}
                                        render={({field, fieldState}) => (
                                            <FullWidthInputText
                                                autoComplete="off"
                                                value={field.value || ""}
                                                onChange={(e) => {
                                                    setValue(
                                                        "organization.coords",
                                                        e.target.value.split(",").map((e) => parseFloat(e)),
                                                        {shouldDirty: true}
                                                    )
                                                }}
                                                className={classNames({'p-invalid': fieldState.invalid})}
                                            />
                                        )}
                                    />
                                </div>
                            </FieldsGrid>
                        </div>
                    </Panel>
                    <Panel className="page__panel page-panel">
                        <div className="page-panel__header">Карта</div>
                        <div>
                            <Controller
                                name="coords"
                                control={control}
                                render={({field, fieldState}) => (
                                    <Map
                                        width="100%"
                                        height="300px"
                                        onClick={(e) => {
                                            setValue("coords", e.get('coords'))
                                        }}
                                        state={{center: field.value || [55.752490, 37.623205], zoom: 18}}
                                    >
                                        {field.value && <Placemark
                                            onDragend={(e) => {
                                                setValue("coords", placer.current.geometry.getCoordinates())
                                            }}
                                            instanceRef={placer} options={{draggable: true,}}
                                            geometry={field.value}
                                        />}

                                        <SearchControl
                                            instanceRef={searchControl}
                                            onResultselect={(e) => {
                                                searchControl.current.getResult(e.get('index')).then((res) => {
                                                    setValue("coords", res.geometry.getCoordinates(), {shouldDirty: true})
                                                })
                                            }}
                                            options={{noPlacemark: true, float: "right"}}
                                        />
                                    </Map>
                                )}/>
                        </div>
                    </Panel>
                </form>
            </div>}
            </>);
}

export default Organization;
