import {types} from "mobx-state-tree";

import ClientStore from "../../store/Client";
import OrganizationStore from "../../store/Organization";
import PharmacyStore from "../../store/Pharmacy";
import {UserStore} from "../../entyties/User/model/UserStore";
import {Mkb10Store} from "../../entyties/Mkb10/model/Mkb10Store";
import {DoctorUIState} from "../../pages/DoctorUI/model/DoctorUIState";
import {PharmacistUIState} from "../../pages/PharmacyUI/model/PharmacistUIState";
import Rp from "../../store/Rp";


const Store = types
    .model("Store", {
        users: types.optional(UserStore, {}),
        clients: types.optional(ClientStore, {}),
        mkb10list: types.optional(Mkb10Store, {}),
        doctorUI: types.optional(DoctorUIState, {}),
        pharmacistUI: types.optional(PharmacistUIState, {}),
        organizations: types.optional(OrganizationStore, {}),
        pharmacies: types.optional(PharmacyStore, {}),
        rps: types.optional(Rp, {})
    })
    .actions(self => ({
        setUser(user) {
            self.users.user = user
            if (user.person)
                self.doctorUI.setDoctorId(user.person.id)
        }
    }));

export const store = Store.create()