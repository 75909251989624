import React from "react";

import {Button} from "primereact/button";

import ButtonProp from "../../shared/ui/ButtonProp";


export default function Header(props) {
    const {backLink, title, controlButtons} = props;

    return (
        <div className="header page__header">
            <div>
                <div className="header__title">
                    {backLink && <div className="btn-back">
                        <Button text icon={"pi pi-chevron-left"} size="large" onClick={backLink}/>
                    </div>}
                    <span>{title}</span>
                </div>
            </div>
            <div className="header__controls">
                {controlButtons && controlButtons.map(
                    (button, index) => {
                        if (button.visible) {
                            return (<ButtonProp
                                className="page-control__button"
                                label={button.label}
                                onClick={button.action}
                                key={index}
                            />)
                        }
                    }
                )}
                <Button link icon={"pi pi-bell"} size="large"/>
            </div>
        </div>
    )
}