import React, {useContext, useState, useRef} from "react";

import {Toast} from "primereact/toast";
import {Password} from "primereact/password";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";

import {AppContext} from "../../../app/AppContext";
import useStore from "../../../hooks/useStore";

export default function ChangePasswordDialog({setShow}) {
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("");
    const toast = useRef(null)
    const context = useContext(AppContext)
    const {user} = useStore();

    const onSetPassword = () => {
        if (confirmPassword !== newPassword) {
            toast.current.show({severity: 'error', summary: 'Внимание', detail: "Пароли не совпадают", life: 3000});
        } else {
            context.apiService.changeUserPassword(user, oldPassword, newPassword).then(data => {
                if (data === true) setShow(false);
            }).catch(data => {
                toast.current.show({
                    severity: 'error',
                    summary: 'Внимание',
                    detail: "Неверный пароль или имя пользователя",
                    life: 3000
                });
            })
        }
    }

    const passwordDialogFooter = (
        <React.Fragment>
            <Button
                label="Установить новый пароль"
                onClick={() => onSetPassword()}
            />
        </React.Fragment>
    );

    return (
        <Dialog
            visible={true}
            onHide={() => setShow(false)}
            footer={passwordDialogFooter}
            style={{height: "290px"}}>
            <Toast ref={toast} position="top-center"/>
            <div><b>Старый пароль</b></div>
            <Password
                className="mt-1"
                feedback={false}
                inputStyle={{width: "300px"}}
                value={oldPassword}
                toggleMask
                onChange={(e) => {
                    setOldPassword(e.target.value)
                }}
            />
            <div><b>Новый пароль</b></div>
            <Password
                className="mt-1"
                inputStyle={{width: "300px"}}
                value={newPassword}
                feedback={false}
                toggleMask
                onChange={(e) => {
                    setNewPassword(e.target.value)
                }}
            />
            <div><b>Подтвердите пароль</b></div>
            <Password
                className="mt-1"
                inputStyle={{width: "300px"}}
                value={confirmPassword}
                feedback={false}
                toggleMask
                onChange={(e) => {
                    setConfirmPassword(e.target.value)
                }}
            />
        </Dialog>
    )
}