import React, {useContext, useEffect, useState} from 'react';
import {NavLink, useHistory, Link} from "react-router-dom";
import classNames from 'classnames'

import {Avatar} from 'primereact/avatar';

import manual from "../../../shared/assets/01_РИ _Врача_.pdf"
import logo from "../../../shared/assets/logo4.png"

import ChangePasswordDialog from "./ChangePasswordDialog";
import {AppContext} from "../../../app/AppContext";


export default function LeftMenu({menuContent}) {
    const {user} = useContext(AppContext)
    const [showPasswordDialog, setShowPasswordDialog] = useState(false)
    const [visible, setVisible] = useState(false);
    const [visibleSubMenu, setVisibleSubMenu] = useState(false);
    const [subMenuContent, setSubMenuContent] = useState(false);
    const history = useHistory()

    useEffect(() => {
        const divFog = document.createElement("div");
        divFog.classList.add("left-menu__content-fog");
        document.body.appendChild(divFog);
        return () => {
            document.body.removeChild(divFog);
        }
    }, []);

    const hideMenu = () => {
        setVisible(false);
        setVisibleSubMenu(false);
        const divFogElement = document.querySelector(".left-menu__content-fog")
        if (divFogElement)
            divFogElement.classList.remove("active");
    }

    const showSubMenu = (subMenuContent) => {
        setVisibleSubMenu(false);
        setSubMenuContent(false);
        if (subMenuContent !== undefined) {
            const divFogElement = document.querySelector(".left-menu__content-fog")
            if (divFogElement)
                divFogElement.classList.remove("active");
            if (subMenuContent) {
                setSubMenuContent(subMenuContent)
                setVisibleSubMenu(true);
                if (divFogElement)
                    divFogElement.classList.add("active");
            }
        }
    }

    const logOut = () => {
        sessionStorage.removeItem("token")
        sessionStorage.removeItem("refresh_token")
        history.push("/Login")
    }

    function generateSubMenuContent(subMenuItems) {
        if (subMenuItems.length > 0) {
            return (<ul className="sub-menu__menu">
                {subMenuItems.map(({to, title}, index) => <li key={index} className="sub-menu__item">
                    <Link className="sub-menu__link" to={to}>{title}</Link>
                </li>)}
            </ul>);
        }
    }

    const userSubMenuContent = (<div>
        <div className="sub-menu__user">
            <div className="avatar__text">
                <div className="text-">{user.roles[0].description}</div>
                <div className="font-medium">{`${user.person.surname} ${user.person.name}`}</div>
            </div>
            <Avatar icon="pi pi-user" size="large" className="border-round avatar__icon"/>
        </div>
        <ul className="sub-menu__menu">
            <li className="sub-menu__item">
                <span onClick={() => setShowPasswordDialog(!showPasswordDialog)} className="sub-menu__link">Сменить пароль</span>
            </li>
            <li className="sub-menu__item">
                <a href={manual} download className="sub-menu__link">Инструкция</a>
            </li>
            <li className="sub-menu__item">
                <span onClick={() => logOut()} className="sub-menu__link">Выйти</span>
            </li>
        </ul>
    </div>);

    return (
        <div className="layout__left-menu">
            {showPasswordDialog && <ChangePasswordDialog setShow={setShowPasswordDialog}/>}
            <div
                className="left-menu"
                onMouseEnter={(e) => setVisible(true)}
                onMouseLeave={(e) => hideMenu()}>
                <div className={classNames({'left-menu': true, 'left-menu_shrink': true, 'active': !visible})}>
                    <div
                        className="left-menu__content select-none">
                        <div className="flex flex-column h-full">
                            <div
                                className="left-menu__logo logo-container">
                                <img className="logo" src={logo} alt={"Логотип"}/>
                                <span className="logo__text">PROPISI.INFO</span>
                            </div>
                            <div className="overflow-y-auto">
                                <ul className="list-none p-0 m-0 overflow-hidden">
                                    {menuContent.map(({to, title, icon, deep, subMenu}, index) =>
                                        <li key={index} className="link"
                                            onMouseEnter={(e) => showSubMenu(generateSubMenuContent(subMenu))}>
                                            {subMenu.length === 0 && <NavLink
                                                to={to}
                                                className="link__item p-ripple transition-duration-150 transition-colors"
                                                isActive={(match, location) => {
                                                    if (deep) {
                                                        if (match) return location.pathname.indexOf(match.url) !== -1;
                                                    } else {
                                                        if (match) return match.isExact
                                                    }
                                                }}>
                                                <i className={icon + ' link__icon pi'}></i>
                                                <span className="link__text font-medium">{title}</span>
                                            </NavLink>}
                                            {subMenu.length > 0 && <div
                                                className="link__item p-ripple transition-duration-150 transition-colors">
                                                <i className={icon + ' link__icon pi'}></i>
                                                <span className="link__text font-medium">{title}</span>
                                                <i className="link__chevron pi pi-chevron-right ml-auto mr-1"></i>
                                            </div>}
                                        </li>)}
                                </ul>
                            </div>
                            <div className="mt-auto left-menu__avatar"
                                 onMouseEnter={(e) => showSubMenu(userSubMenuContent)}>
                                <hr className="mb-3 border-top-1 border-none surface-border w-full"/>
                                <div className="avatar">
                                    <Avatar icon="pi pi-user" size="large" className="border-round avatar__icon"/>
                                    <div className="avatar__text">
                                        <div className="text-">{user.roles[0].description}</div>
                                        <div className="font-medium">{`${user.person.surname} ${user.person.name}`}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            onMouseLeave={(e) => setVisibleSubMenu(false)}
                            className={classNames({
                                'left-menu__sub-menu': true,
                                'sub-menu': true,
                                'active': visibleSubMenu
                            })}>
                            {subMenuContent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}