import React, {useRef, useState} from 'react'
import {usePrescriptionsStore} from "../../model/PrescriptionsStore";
import {observer} from "mobx-react-lite";
import {BlockUI} from "../../../../shared/ui/BlockUI";
import {ItemsGridStyled} from "./ItemsGrid.styled";
import {CenteredItemStyled, CenteredLabelStyled} from "../../../../shared/ui/CenteredItems.styled";
import DateEditor from "../../../../shared/ui/DateEditor/DateEditor";
import {Checkbox} from "primereact/checkbox";
import {Button} from "primereact/button";
import useStore from "../../../../hooks/useStore";
import RpItemsEditor from "./RpItemsEditor";
import {InputTextarea} from "primereact/inputtextarea";
import ListItemEditor from "../../../../shared/ui/ListItemEditor";
import NumberEditor from "../../../../shared/ui/NumberEditor";
import ApiService from "../../../../shared/api/ApiService";
import {Toast} from "primereact/toast";
import {applySnapshot, clone} from "mobx-state-tree";
import RpList from "../../../Rp/ui/RpList";
import {RpLecform} from "../../../LecForm/model/RpLecform";
import ItemsList from "../../../../shared/ui/ItemsList";
import {useMatchMedia} from "../../../../shared/lib/useMatch";
import MobileRpList from "../../../Rp/ui/MobileRpList";
import ButtonProp from "../../../../shared/ui/ButtonProp";

function PrescriptionEditor(props) {
    const {user} = useStore();
    const store = usePrescriptionsStore()
    const prescription = store.editable
    const {onSave} = props
    const {isMobile} = useMatchMedia();

    const [showRpList, setShowRpList] = useState(false);
    const [displayLecFormList, setDisplayLecFormList] = useState(false);
    // const [rpItemsEdited, setRpItemsEdited] = useState(false);

    const gridStyle = {
        display: "grid",
        gridTemplateColumns: "auto auto auto 1fr",
        gridRowGap: "0.5rem",
        gridColumnGap: "0.5rem",
        alignItems: "center"
    }

    const [fields, setFields] = useState({
        "mds": {
            required: true,
            invalid: false,
            isValid: (value) => {
                return value !== ""
            }
        },
        "dtd": {
            required: true,
            invalid: false,
            isValid: (value) => {
                return value !== "" && value > 0
            }
        },
        "daysCount": {
            required: true,
            invalid: false,
            isValid: (value) => {
                return value !== "" && value > 0
            }
        }

    })

    const toast = useRef(null);

    const showError = (msg) => {
        toast.current.show({severity: 'error', summary: 'Внимание', detail: msg, life: 15000});
    }

    const checkPrescription = () => {
        let saveFields = false
        const invalidateField = (name) => {
            let field = fields[name];
            if (field) {
                field.invalid = true
                saveFields = true
            }
        }

        let messages = []
        // if (rpItemsEdited){
        //     messages.push("Компоненты находятся в режиме редактирования")
        // }
        if (!prescription.patient) {
            messages.push("В назначении отсутствует пациент!")
        }
        if (prescription.items.length === 0) {
            messages.push("В назначении отсутствуют компоненты!")
        }
        if (!prescription.mds || prescription.mds === "") {
            messages.push("В назначении отсутствует M.D.S!")
            invalidateField("mds")
        }
        if (!prescription.lecForm || prescription.lecForm === "") {
            messages.push("В назначении отсутствует форма выпуска!")
            invalidateField("lecForm")
        }
        if (!prescription.dtd || prescription.dtd === 0) {
            messages.push("В назначении отсутствует кол-во на курс!")
            invalidateField("dtd")
        }
        if (messages.length > 0) {
            showError(messages[0])
        }
        if (saveFields) setFields({...fields})
        return messages.length === 0
    }

    const onSavePrescription = () => {
        if (checkPrescription()) {
            ApiService.savePrescription(prescription).then(data => {
                if (onSave)
                    onSave(data)
                applySnapshot(prescription, data)
                prescription.setIsDirty(false)
            }).catch(e => {
                showError(e.message)
            })
        }
    }

    const onSendToPharmacy = () => {
        if (checkPrescription()) {
            ApiService.sendToPharmacy(prescription).then(data => {
                applySnapshot(prescription, data)
                prescription.setIsDirty(false)
            }).catch(e => {
                showError(e.message)
            })
        }
    }

    return (
        <div>
            <Toast ref={toast} position="top-center"/>
            {!isMobile && showRpList && <RpList
                visible={showRpList}
                onHide={() => {
                    setShowRpList(false)
                }}
                onSelect={(data) => {
                    prescription.setRp(clone(data))
                    prescription.setRpName(data.name)
                    if (data.lecForm)
                        prescription.setLecForm(clone(data.lecForm))
                    prescription.setMds(data.mds)
                    prescription.setAction(data.action)
                    prescription.clearItems()
                    data.items.forEach(i => {
                        const item = clone(i)
                        item.setId(undefined)
                        prescription.addItem(item)
                    })
                    prescription.setPrice(data.price)
                    setShowRpList(false)
                }}
            />}
            {isMobile && <MobileRpList
                visible={showRpList}
                onHide={() => {
                    setShowRpList(false)
                }}
                onSelect={(data) => {
                    prescription.setRp(clone(data))
                    prescription.setRpName(data.name)
                    if (data.lecForm)
                        prescription.setLecForm(clone(data.lecForm))
                    prescription.setMds(data.mds)
                    prescription.setAction(data.action)
                    prescription.clearItems()
                    data.items.forEach(i => {
                        const item = clone(i)
                        item.setId(undefined)
                        prescription.addItem(item)
                    })
                    prescription.setPrice(data.price)
                    setShowRpList(false)
                }}
            />}
            <ItemsList
                header="Формы выпуска"
                visible={displayLecFormList}
                onHide={() => setDisplayLecFormList(false)}
                getData={ApiService.getRpLecForms.bind(ApiService)}
                onAccept={(e) => {
                    prescription.setLecForm(RpLecform.create(e))
                    setDisplayLecFormList(false)
                }}
            />
            <BlockUI blocking={store.isLoading}>
                <div style={{margin: "16px 0", display: "flex", flexFlow: "row nowrap", justifyContent: "space-between"}}>
                    <div>
                        <span className="header" style={{fontWeight: "bold", fontSize: "14px"}}>Пациент: </span>
                        <span
                            style={{fontSize: "16px"}}>{`${prescription?.patient?.fullName} `}</span>
                    </div>
                    <div>
                        {(!prescription.isChanged && prescription.sentToPharmacy) && <ButtonProp
                            icon="pi pi-print"
                            label="Напечатать назначение"
                            onClick={() => {
                                ApiService.downloadPrintForm(`api/recipe/${prescription.id}`)
                            }}
                        />}
                    </div>
                </div>
                {!isMobile && <ItemsGridStyled>
                    <span style={{fontWeight: "bold", fontSize: "14px"}}>Дата назначения:</span>
                    <div style={{...gridStyle, fontSize: "14px"}}>

                        {!prescription.sentToPharmacy && <DateEditor
                            overlayPos="bottom"
                            value={prescription.prescription_date || ""}
                            onChange={(e) => {
                                prescription.setDate(e)
                            }}
                        />}

                        {prescription.sentToPharmacy &&
                            <div>{prescription.prescription_date?.toLocaleDateString()}</div>}

                        <span style={{marginLeft: "24px", fontWeight: "bold", fontSize: "14px"}}>Хроническое</span>
                        <CenteredItemStyled>
                            <Checkbox
                                checked={prescription.chronic}
                                onChange={(e) => {
                                    prescription.setChronic(e.checked)
                                }}
                                readOnly={prescription.sentToPharmacy}
                            />
                        </CenteredItemStyled>
                    </div>
                    {user?.isDoctor && <span style={{fontWeight: "bold", fontSize: "14px", alignItems: "center", display: "flex"}}>Готовые прописи:</span>}
                    {user?.isDoctor && <div style={{textAlign: "left"}}>
                        <div style={gridStyle}>
                            <ButtonProp
                                type={"button"}
                                label="Выбрать пропись"
                                onClick={() => {
                                    setShowRpList(true)
                                }}
                                disabled={prescription.sentToPharmacy}
                            />
                            <CenteredLabelStyled>{prescription.rpName}</CenteredLabelStyled>
                        </div>
                    </div>}
                </ItemsGridStyled>}
                {isMobile && <div>
                    <CenteredLabelStyled>Дата назначения</CenteredLabelStyled>
                    <div style={gridStyle}>
                        {!prescription.sentToPharmacy && <DateEditor
                            overlayPos="bottom"
                            value={prescription.prescription_date || ""}
                            onChange={(e) => {
                                prescription.setDate(e)
                            }}
                        />}
                        {prescription.sentToPharmacy &&
                            <div>{prescription.prescription_date?.toLocaleDateString()}</div>
                        }
                    </div>
                    <div className={"pt-2 pb-2"} style={gridStyle}>
                        <CenteredLabelStyled>Хроническое</CenteredLabelStyled>
                        <CenteredItemStyled>
                            <Checkbox
                                checked={prescription.chronic}
                                onChange={(e) => {
                                    prescription.setChronic(e.checked)
                                }}
                                readOnly={prescription.sentToPharmacy}
                            />
                        </CenteredItemStyled>
                    </div>
                    <div className={"pt-2 pb-2"} style={gridStyle}>
                        {user?.isDoctor && <CenteredLabelStyled>Готовые прописи</CenteredLabelStyled>}
                        {user?.isDoctor && <div style={{textAlign: "left"}}>
                            <div style={gridStyle}>
                                <ButtonProp
                                    type={"button"}
                                    label="Выбрать пропись"
                                    onClick={() => {
                                        setShowRpList(true)
                                    }}
                                    disabled={prescription.sentToPharmacy}
                                />

                            </div>
                        </div>}
                    </div>
                </div>}

                {!isMobile && <ItemsGridStyled className={"mt-2"} style={{margin: "12px 0"}}>
                    <CenteredLabelStyled>Rp</CenteredLabelStyled>
                    <RpItemsEditor
                        items={prescription.items}
                        addItem={prescription.addItem}
                        removeItem={prescription.removeItem}
                        // setIsEditing={(value) => {
                        //     setRpItemsEdited(value)
                        // }}
                        readOnly={prescription.sentToPharmacy}
                    />
                    <CenteredLabelStyled>M.D.S</CenteredLabelStyled>
                    <InputTextarea
                        value={prescription.mds || ""}
                        onChange={(e) => {
                            prescription.setMds(e.target.value)
                        }}
                        autoResize
                        readOnly={prescription.sentToPharmacy}
                    />
                </ItemsGridStyled>}
                {isMobile && <div>
                    <CenteredLabelStyled
                        className={"mt-2 mb-2"}>{`Rp: ${prescription.rpName ? prescription.rpName : ""}`}</CenteredLabelStyled>
                    <RpItemsEditor
                        items={prescription.items}
                        addItem={prescription.addItem}
                        removeItem={prescription.removeItem}
                        // setIsEditing={(value) => {
                        //     setRpItemsEdited(value)
                        // }}
                        readOnly={prescription.sentToPharmacy}
                    />
                    <CenteredLabelStyled className={"mt-2"}>M.D.S</CenteredLabelStyled>
                    <InputTextarea
                        className={"mt-2"}
                        style={{width: "100%"}}
                        value={prescription.mds || ""}
                        onChange={(e) => {
                            prescription.setMds(e.target.value)
                        }}
                        autoResize
                        readOnly={prescription.sentToPharmacy}
                    />
                </div>}

                {!isMobile && <ItemsGridStyled className={"mt-2"}>
                    <CenteredLabelStyled>Форма выпуска</CenteredLabelStyled>
                    <div style={gridStyle}>
                        <ListItemEditor
                            value={prescription.lecForm?.name}
                            onSelect={() => {
                                setDisplayLecFormList(true)
                            }}
                            readOnly={prescription.sentToPharmacy}
                        />
                        <CenteredLabelStyled>Кол-во на курс</CenteredLabelStyled>
                        {!prescription.sentToPharmacy && <NumberEditor
                            value={prescription.dtd || ""}
                            // className={classNames( {"p-invalid": fields["dtd"].invalid})}
                            allowedDecimalSeparators={[".", ","]}
                            onChange={(e) => prescription.setDTD(e.target.value)}
                            readOnly={prescription.sentToPharmacy}
                        />}
                        {prescription.sentToPharmacy && <div>{prescription.dtd}</div>}
                    </div>
                    <CenteredLabelStyled>Дата начала</CenteredLabelStyled>
                    <div style={{...gridStyle, gridTemplateColumns: "auto auto auto auto auto auto auto auto 1fr"}}>
                        {!prescription.sentToPharmacy && <DateEditor
                            overlayPos="top"
                            value={prescription.begin_date || ""}
                            onChange={(e) => prescription.setBeginDate(e)}
                        />}
                        {prescription.sentToPharmacy && <div>{prescription.begin_date?.toLocaleDateString()}</div>}
                        <CenteredLabelStyled>Кол-во дней</CenteredLabelStyled>
                        {!prescription.sentToPharmacy && <NumberEditor
                            value={prescription.daysCount}
                            onChange={(e) => prescription.setDaysCount(e.target.value)}
                            // className = {classNames( {"p-invalid": fields["daysCount"].invalid})}
                            readOnly={prescription.sentToPharmacy}
                        />}
                        {prescription.sentToPharmacy && <div>{prescription.daysCount}</div>}
                        <CenteredLabelStyled>Дата окончания</CenteredLabelStyled>
                        {!prescription.sentToPharmacy && <DateEditor
                            overlayPos="top"
                            value={prescription.end_date || ""}
                            onChange={(e) => prescription.setEndDate(e)}
                        />}
                        {prescription.sentToPharmacy && <div>{prescription.end_date?.toLocaleDateString()}</div>}
                    </div>
                    <CenteredLabelStyled>Цена</CenteredLabelStyled>
                    <NumberEditor
                        value={prescription.price || ""}
                        allowedDecimalSeparators={[".", ","]}
                        onValueChange={(e) => {
                            prescription.setPrice(e.floatValue)
                        }}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        suffix=" p"
                        readOnly={true}
                    />
                </ItemsGridStyled>}
                {isMobile && <div style={{display: "flex", alignItems: "center", justifyContent: "left"}}>
                    <div>
                        <div className={"pt-2 pb-2"} style={gridStyle}>
                            <CenteredLabelStyled>Форма выпуска</CenteredLabelStyled>
                            <ListItemEditor
                                value={prescription.lecForm?.name}
                                onSelect={() => {
                                    setDisplayLecFormList(true)
                                }}
                                readOnly={prescription.sentToPharmacy}
                            />
                        </div>
                        <div className={"pt-2 pb-2"}>
                            <CenteredLabelStyled>Кол-во на курс</CenteredLabelStyled>
                            {!prescription.sentToPharmacy && <NumberEditor
                                value={prescription.dtd || ""}
                                // className={classNames( {"p-invalid": fields["dtd"].invalid})}
                                allowedDecimalSeparators={[".", ","]}
                                onChange={(e) => prescription.setDTD(e.target.value)}
                                readOnly={prescription.sentToPharmacy}
                            />}
                        </div>
                        <div className={"pt-2 pb-2"}>
                            {prescription.sentToPharmacy && <div>{prescription.dtd}</div>}
                            <CenteredLabelStyled>Дата начала</CenteredLabelStyled>
                            {!prescription.sentToPharmacy && <DateEditor
                                overlayPos="top"
                                value={prescription.begin_date || ""}
                                onChange={(e) => prescription.setBeginDate(e)}
                            />}
                        </div>
                        <div className={"pt-2 pb-2"}>
                            {prescription.sentToPharmacy && <div>{prescription.begin_date?.toLocaleDateString()}</div>}
                            <CenteredLabelStyled>Кол-во дней</CenteredLabelStyled>
                            {!prescription.sentToPharmacy && <NumberEditor
                                value={prescription.daysCount}
                                onChange={(e) => prescription.setDaysCount(e.target.value)}
                                readOnly={prescription.sentToPharmacy}
                            />}
                            {prescription.sentToPharmacy && <div>{prescription.daysCount}</div>}
                        </div>
                        <div className={"pt-2 pb-2"}>
                            <CenteredLabelStyled>Дата окончания</CenteredLabelStyled>
                            {!prescription.sentToPharmacy && <DateEditor
                                overlayPos="top"
                                value={prescription.end_date || ""}
                                onChange={(e) => prescription.setEndDate(e)}
                            />}
                            {prescription.sentToPharmacy && <div>{prescription.end_date?.toLocaleDateString()}</div>}
                        </div>

                        <CenteredLabelStyled>Цена</CenteredLabelStyled>
                        <NumberEditor
                            value={prescription.price || ""}
                            allowedDecimalSeparators={[".", ","]}
                            onValueChange={(e) => {
                                prescription.setPrice(e.floatValue)
                            }}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            suffix=" p"
                            readOnly={true}
                        />

                    </div>
                </div>}

            </BlockUI>
            <div style={{textAlign: "center"}} className={"pt-2"}>
                <Button
                    visible={prescription.isChanged}
                    onClick={onSavePrescription}
                    label="Сохранить изменения"
                />
                {(!prescription.isChanged && prescription.id && !prescription.sentToPharmacy) && <Button
                    icon="pi pi-send"
                    severity="success"
                    label="Отправить в аптеку"
                    onClick={onSendToPharmacy}
                />}
            </div>
        </div>
    )
}

export default observer(PrescriptionEditor)
