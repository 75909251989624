import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";

import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

import "../../../shared/scss/Resizer.css"
import {AppContext} from "../../../app/AppContext";
import {BlockUI} from "../../../shared/ui/BlockUI";
import {Paginator} from "primereact/paginator";
import {Dialog} from "primereact/dialog";
import {ClearButtonOutlined} from "../../../shared/ui/Styles";
import Header from "../../../components/header";
import {useHistory} from "react-router-dom";
import useStore from "../../../hooks/useStore";


function Pharmacies(props) {
    const history = useHistory();
    const {pharmacies} = useStore();
    const [selectedOrg, setSelectedOrg] = useState(undefined);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    })

    const context = useContext(AppContext);


    useEffect(async () => {
        let isMounted = true
        if (isMounted) {
            await pharmacies.selectPharmaciesList(lazyParams);
        }
        return () => {
            isMounted = false
        }
    }, [lazyParams])

    const onChangePage = (e) => {
        setLazyParams({...lazyParams, first: e.first, rows: e.rows, page: e.page})
    }

    const deleteTemplate = (rowData, options) => {
        return <ClearButtonOutlined
            style={{width: "20px", height: "20px"}}
            type="button"
            icon='pi pi-trash'
            severity="danger"
            className="p-button-sm p-button-text"
            onClick={() => {
                setSelectedOrg(rowData);
                setShowDeleteDialog(true)
            }}
        />;
    };

    const deleteDialogFooterContent = (
        <div>
            <Button label="Да" icon="pi pi-check" severity="danger" onClick={() => {
                context.apiService.deletePharmacy(selectedOrg).then(() => {
                    // setItems(items.filter(i=>i.id !== selectedOrg.id))
                    setSelectedOrg(undefined)
                })
                setShowDeleteDialog(false)
            }}/>
            <Button label="Нет" icon="pi pi-check" onClick={() => setShowDeleteDialog(false)} autoFocus/>
        </div>
    );

    return (
        <div>

            {showDeleteDialog && <Dialog
                header="ВНИМАНИЕ"
                visible={showDeleteDialog}
                onHide={() => {
                    setShowDeleteDialog(false)
                }}
                footer={deleteDialogFooterContent}
            >
                <div>
                    Удалить аптеку:
                </div>
                <div className={"mt-2"}>
                    <strong>{selectedOrg?.name}?</strong>
                </div>
            </Dialog>}
            <div>
                <Header title={"Аптеки"} controlButtons={[{
                    label: "Добавить",
                    action: () => history.push("/admin/pharmacy/0"),
                    visible: true
                }]}/>
                <BlockUI blocking={pharmacies.isLoading}>
                    <DataTable
                        dataKey="id"
                        value={pharmacies.pharmacies}
                        selection={selectedOrg}
                        selectionMode="single"
                        onRowClick={(e) => {
                            history.push(`/admin/pharmacy/${e.data.id}`)
                        }}
                    >
                        <Column header="Наименование" field="name"/>
                        <Column header="Телефон" field="phone"/>
                        <Column header="Емайл" field="email"/>
                        <Column style={{width: "30px"}} body={deleteTemplate}></Column>
                    </DataTable>
                    <Paginator
                        alwaysShow={false}
                        style={{justifyContent: "flex-end"}}
                        rows={lazyParams.rows}
                        first={lazyParams.first}
                        totalRecords={pharmacies.total}
                        onPageChange={onChangePage}
                    />
                </BlockUI>
            </div>
        </div>
    );
}

export default observer(Pharmacies);
