import React, {useEffect, useState} from "react";
import 'react-block-ui/style.css';

import {Dialog} from "primereact/dialog";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

import {BlockUI} from "../BlockUI";
import ButtonProp from "../ButtonProp";
import LabeledSearchInput from "../LabeledSearchInput";


const ItemsList = (props) => {

    const {showEditor, setShowEditor, editor, initialValue} = props
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0)

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        name: initialValue || "",
    })

    useEffect(()=>{
        if (props.clearPrevSearchItem && lazyParams.name!=="")
            setLazyParams({...lazyParams, name:""})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.visible])

    useEffect(()=>{
        setLazyParams({...lazyParams, name:initialValue || ""})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initialValue])

    useEffect(()=>{
        let isMounted = true
        const loadLazyData = () => {
            setLoading(true);
            props.getData(lazyParams).then(data => {
                if (isMounted){
                    setTotalElements(data?.totalElements);
                    setItems(data?.content);
                    setLoading(false);
                }
            });
        }
        if (props.getData !== undefined && props.visible){
            loadLazyData();
        }
        return ()=>isMounted = false
    },[lazyParams, props])

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onAccept = (event) => {
        if (props.onAccept !== undefined){
            props.onAccept(event.value)
        }
    }

    if (!props.visible) return <></>

    //let columns = undefined
    if (props.columns){
        // columns = props.columns.map((column) => {
        //     return <Column key="id" field={column.field} header={column.header} />
        // })
    }

    const btnOnClick = () => { if(setShowEditor) setShowEditor(true); }
    const searchOnChange = (e) => { setLazyParams({...lazyParams, first:0, page:0, name: e.target.value}) }

    return(
        <Dialog
            header="Выбор компонента"
            onHide={props.onHide}
            visible={props.visible}
            style={{ width: '80%' }}
        >
            <div className="container p-1">
                {<div style={{margin: "0.8rem 0 2rem"}}>
                    <ButtonProp label="Добавить позицию в список" onClick={btnOnClick} />
                    {showEditor && editor}
                </div>}
                <LabeledSearchInput label="Поиск" value={lazyParams.name} onChange={searchOnChange} style={{marginBottom: "1rem"}} />
            </div>
            <div className="datatable-with-header">
                <BlockUI blocking={loading}>
                <DataTable
                    className="page-datatable"
                    value={items} lazy
                    selectionMode="single"
                    onSelectionChange={onAccept}
                    paginator first={lazyParams.first} rows={lazyParams.rows} totalRecords={totalElements}
                    rowsPerPageOptions={[10,20]} onPage={onPage}
                    dataKey="id"
                >
                    <Column field="name" header="Наименование латынь"/>
                    <Column field="ru_name" header="Наименование русский"/>
                    <Column field="en_name" header="Наименование английский"/>
                </DataTable>
                </BlockUI>
            </div>
        </Dialog>
    )
}

export default ItemsList
