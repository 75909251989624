import React, {useEffect, useState} from 'react'
import {useHistory} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {getSnapshot} from "mobx-state-tree";
import {format, parseISO} from "date-fns";

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Paginator} from "primereact/paginator";
import {Button} from "primereact/button";

import ButtonProp from "../../../../shared/ui/ButtonProp";
import {BlockUI} from "../../../../shared/ui/BlockUI";
import {useMatchMedia} from "../../../../shared/lib/useMatch";
import {usePrescriptionsStore} from "../../model/PrescriptionsStore";
import PrescriptionDialog from "../../../../pages/DoctorUI/ui/PatientCard/PrescriptionDialog";
import {Panel} from "primereact/panel";


function PrescriptionList(props) {
    const {store, patient_id} = props;
    const history = useHistory();
    const [prescriptionsParams, setPrescriptionsParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    })
    const prescriptionStore = usePrescriptionsStore();
    const [showPrescriptionDialog, setShowPrescriptionDialog] = useState(false)
    const {isMobile} = useMatchMedia();

    useEffect(() => {
        prescriptionStore.fetch({
            ...prescriptionsParams,
            patient_id: patient_id,
            doctor_id: store.doctorId
        })
    }, [prescriptionStore, prescriptionsParams, patient_id, store.doctorId])
    const dateBody = (rowData) => {
        return format(parseISO(rowData.date), "dd.MM.yyyy")
    }

    const onPageChange = (e) => {
        setPrescriptionsParams({...prescriptionsParams, first: e.first, rows: e.rows, page: e.page})
    }

    const btnOnClick = () => {
        prescriptionStore.initEditable({
            items: [],
            patient: getSnapshot(store.patient),
            date: new Date().toISOString()
        });
        setShowPrescriptionDialog(true);
    }

    function getAgeByBirthDate(birthDate) {
        const year = new Date().getFullYear();
        const birthYear = new Date(birthDate).getFullYear();
        const age = year - birthYear;
        if (age)
            return age;
        else
            return null;
    }

    return (
        <div className="page">
            {showPrescriptionDialog && <PrescriptionDialog
                visible={showPrescriptionDialog} onHide={() => setShowPrescriptionDialog(false)}
                onSave={(data) => {
                    prescriptionStore.updatePrescription(data)
                }}
            />}
            <div className="page__header">
                <div>
                    <div style={{display: "flex", alignItems: "center", gap: "18px"}}>
                        <Button
                            style={{borderRadius: "8px", fontWeight: "500", fontSize: "16px", padding: "10px 16px"}}
                            text
                            icon={"pi pi-chevron-left"}
                            size="large"
                            onClick={() => {
                                history.push("/doctor/patients")
                            }}/>
                        <span style={{fontSize: "36px", fontWeight: "bold"}}>Пациент: {store.patient.fullName}</span>
                    </div>
                    <div className="control-buttons">
                        <ButtonProp label="Выписать назначение" isMobile={isMobile} onClick={btnOnClick}/>
                    </div>
                </div>
                <Panel className="page__details-panel" header="Информация пользователя">
                <div className="page__details">
                    <div className="page__info">
                        {getAgeByBirthDate(store.patient.person.birthDate) &&
                            <div><span>Возраст: </span> {getAgeByBirthDate(store.patient.person.birthDate)}</div>}
                        {store.patient.person.weight && <div><span>Вес: </span> {store.patient.person.weight}</div>}
                        {store.patient.person.gender && <div><span>Пол: </span> {store.patient.person.gender}</div>}
                        {store.patient.person.email && <div><span>Email: </span> <a
                            href={store.patient.person.email}>{store.patient.person.email}</a></div>}
                        {store.patient.person.phone &&
                            <div><span>Телефон: </span> {store.patient.person.phone}</div>}
                        {store.patient.person.snils &&
                            <div><span>СНИЛС: </span> {store.patient.person.snils}</div>}
                    </div>
                </div>
                </Panel>
            </div>
            <BlockUI blocking={prescriptionStore.isLoading}>
                {!prescriptionStore.isLoading && <div>
                    <div className="page-subheader">Назначения</div>
                    <DataTable
                        className="page-datatable"
                        value={prescriptionStore.items}
                        selection={prescriptionStore.selected}
                        selectionMode="single"
                        onSelectionChange={(e) => {
                            if (e.value) {
                                prescriptionStore.setSelected(e.value)
                                prescriptionStore.initEditable(getSnapshot(e.value))
                                setShowPrescriptionDialog(true)
                            }
                        }}
                        emptyMessage={"Назначений еще нет"}
                        // onClick={(e) => {
                        //     prescriptionStore.initEditable(getSnapshot(prescriptionStore.selected))
                        //     setShowPrescriptionDialog(true)
                        // }}
                    >
                        <Column body={dateBody} header={"Дата "}/>
                        <Column field={"id"} header={"Номер"}/>
                        <Column field={"description"} header={"Состав"}/>
                    </DataTable>
                    <Paginator
                        alwaysShow={false}
                        style={{justifyContent: "flex-end"}}
                        rows={prescriptionsParams.rows}
                        first={prescriptionsParams.first}
                        totalRecords={prescriptionStore.total}
                        onPageChange={onPageChange}
                    />
                </div>}
            </BlockUI>
        </div>
    )
}

export default observer(PrescriptionList)
