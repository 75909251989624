import React, {useContext, useRef, useState} from 'react';

import {Menu} from "primereact/menu";
import {Button} from "primereact/button";
import {useHistory} from "react-router-dom";
import useStore from "../../../hooks/useStore";
import {observer} from "mobx-react-lite";
import {Password} from "primereact/password";
import {Dialog} from "primereact/dialog";
import {AppContext} from "../../../app/AppContext";
import {Toast} from "primereact/toast";

function UserMenu(props) {
    const [showPasswordDialog, setShowPasswordDialog] = useState(false)
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("");
    const userMenu = useRef(null)
    const toast =useRef(null)
    const history = useHistory()
    const context = useContext(AppContext)
    const {user} = useStore();
    const logOut = () => {
        sessionStorage.removeItem("token")
        sessionStorage.removeItem("refresh_token")
        history.push("/Login")
    }

    const changePassword = () => {
        setOldPassword("")
        setNewPassword("")
        setConfirmPassword("")
        setShowPasswordDialog(true)
    }

    const onSetPassword = () =>{
        if (confirmPassword !== newPassword){
            toast.current.show({severity: 'error', summary: 'Внимание', detail: "Пароли не совпадают", life: 3000});
        } else {
            context.apiService.changeUserPassword(user, oldPassword, newPassword).then(data =>{
                if (data===true){ setShowPasswordDialog(false)}
            }).catch(data=>{
                toast.current.show({severity: 'error', summary: 'Внимание', detail: "Неверный пароль или имя пользователя", life: 3000});
            })
        }
    }

    const userMenuItems = [
        {
            label: "Выйти",
            icon: 'pi pi-sign-out',
            command: () => {
                logOut()
            }
        },
        {
            label: "Сменить пароль",
            icon: 'pi pi-key',
            command: () => {
                changePassword()
            }
        }
    ]

    const passwordDialogFooter = (
        <React.Fragment>
            <Button
                label="Установить новый пароль"
                onClick={()=>onSetPassword()}
            />
        </React.Fragment>
    );

    return (
        <div>

            <Dialog visible={showPasswordDialog}
                    onHide={()=>{setShowPasswordDialog(false)}}
                    footer={passwordDialogFooter}
                    style={{height:"290px"}}
            >
                <Toast ref={toast} position="top-center"/>
                <div><b>Старый пароль</b></div>
                <Password
                    className="mt-1"
                    feedback={false}
                    inputStyle={{width:"300px"}}
                    value={oldPassword}
                    toggleMask
                    onChange={(e)=>{setOldPassword(e.target.value)}}
                />
                <div><b>Новый пароль</b></div>
                <Password
                    className="mt-1"
                    inputStyle={{width:"300px"}}
                    value={newPassword}
                    feedback={false}
                    toggleMask
                    onChange={(e)=>{setNewPassword(e.target.value)}}
                />
                <div><b>Подтвердите пароль</b></div>
                <Password
                    className="mt-1"
                    inputStyle={{width:"300px"}}
                    value={confirmPassword}
                    feedback={false}
                    toggleMask
                    onChange={(e)=>{setConfirmPassword(e.target.value)}}
                />
            </Dialog>

            <Menu popup model={userMenuItems} ref={userMenu} id="popup_user_menu"/>
            <Button
                className="p-button-success"
                label={user?.fullName}
                icon="pi pi-user"
                onClick={(event) => userMenu.current.toggle(event)}
                aria-controls="popup_user_menu" aria-haspopup
            />
        </div>
    );
}

export default observer(UserMenu);
