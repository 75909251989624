import {flow, types} from "mobx-state-tree"

import ApiService from "../shared/api/ApiService";


const Client = types.model( "Client", {
    id: types.maybe(types.integer),
    name: types.string,
    inn: types.string,
})

const ClientStore = types.model("ClientStore", {
    active: types.safeReference(Client),
    clients: types.array(Client),
    isLoading: types.maybe(types.boolean),
    total: types.maybe(types.integer),
}).actions(self => ({
    afterCreate: flow(function* () {
        self.isLoading = true;
        const res = yield ApiService.get("clients/");
        self.clients = res.data.content === "" ? [] : res.data.content;
        self.total = res.data.totalElements;
        self.isLoading = false;
    }),
    selectClient(id) {
        self.active = id;
    },
    addClient: flow(function* (clientPayload) {
        const res = yield ApiService.post("clients/", clientPayload);
        if ("id" in clientPayload) {
            let updated = self.clients.find(c => c.id === clientPayload.id)
            Object.assign(updated, res.data);
        }
        else
            self.clients.push({ ...res.data });
    }),
    removeClient: flow(function* (id) {
        yield ApiService.delete(`clients/${id}`);
        const deleted = self.clients.find(c => c.id === id);
        self.clients.remove(deleted);
    })
}));

export default ClientStore;