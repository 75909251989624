import useStore from "../../../hooks/useStore";
import {Route} from "react-router-dom";
import DeniedLabel from "../../../shared/ui/DeniedLabel";
import React from "react";

function RestrictedRoute({ children, ...rest }) {
    const {users} = useStore();
    if (!users.user?.roles || users.user.roles===[]) return (<></>)
    return (
        <Route
            {...rest}
            render = {({ location }) =>
                users.user.roles.find(i=>i.level>=rest.level) ? (
                    children
                ) : (
                    <DeniedLabel/>
                )
            }
        />
    );
}

export default RestrictedRoute