import { types } from "mobx-state-tree"

export const Organization = types.model({
    id: types.maybeNull(types.integer),
    name: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    coords: types.maybeNull(types.array(types.number))
})
