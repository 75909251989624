import React, {useContext, useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import {parseISO} from "date-fns";
import {useHistory} from "react-router-dom";

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Toast} from "primereact/toast";
import {Paginator} from "primereact/paginator";

import {AppContext} from "../../../app/AppContext";
import {ClearButtonOutlined} from "../../../shared/ui/Styles";
import "../../../shared/scss/Resizer.css"
import {BlockUI} from "../../../shared/ui/BlockUI";
import Header from "../../../components/header";
import useStore from "../../../hooks/useStore";



function UserRoles() {
    const { users} = useStore();
    const history = useHistory();

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    })
    const toast = useRef(null)
    const dataTable = useRef(null);

    useEffect(() => {
        let isMounted = true
        if (isMounted)
            users.rolesList(lazyParams);
        return () => {
            isMounted = false
        }
    }, [lazyParams])

    const actionBodyTemplate = (rowData) => {
        return (<div>
            <ClearButtonOutlined
                style={{width: "20px", height: "20px"}}
                className="ml-4 p-button-text"
                type="button"
                onClick={() => {
                    // context.apiService.deleteUser(rowData)
                    // setItems(items.filter(i => i.id !== rowData.id))
                }}
            />
        </div>)
    }

    const formatDate = (value) => {
        return value.toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        })
    };
    const dateBodyTemplate = (rowData) => {
        if (rowData.regDate === null) return null
        return formatDate(parseISO(rowData.regDate));
    };

    const onChangePage = (e) => {
        setLazyParams({...lazyParams, first: e.first, rows: e.rows, page: e.page})
    }

    return (
        <>
            <Toast ref={toast} position="top-center"/>
            <div>
                <Header title={"Роли"} controlButtons={[{
                    label: "Добавить",
                    action: () => history.push("users/roles/0"),
                    visible: true
                }]}/>
                <BlockUI blocking={users.isLoading}>
                    <DataTable
                        className="page-datatable"
                        ref={dataTable}
                        value={users.roles} lazy
                        selectionMode="single"
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        totalRecords={users.total}
                        onRowClick={(e) => {
                            history.push(`roles/${e.data.id}`)
                        }}
                        onPage={onChangePage}
                    >
                        <Column field="description" header="Название"/>
                        <Column body={actionBodyTemplate} style={{width: "5rem"}}/>
                    </DataTable>
                </BlockUI>
                <Paginator
                    alwaysShow={false}
                    style={{justifyContent: "flex-end"}}
                    rows={lazyParams.rows}
                    first={lazyParams.first}
                    totalRecords={users.total}
                    onPageChange={onChangePage}
                />
            </div>
        </>
    );
}

export default observer(UserRoles);
