import React, {useEffect, useState} from 'react'
import {observer} from "mobx-react-lite";

import {useParams} from "react-router-dom";


import {usePrescriptionsStore} from "../../../../entyties/Prescription/model/PrescriptionsStore";
import {DataTable} from "primereact/datatable";
import {getSnapshot} from "mobx-state-tree";
import {Column} from "primereact/column";
import {Paginator} from "primereact/paginator";
import {BlockUI} from "../../../../shared/ui/BlockUI";
import {format, parseISO} from "date-fns";

import LabeledSearchInput from "../../../../shared/ui/LabeledSearchInput";
import PrescriptionDialog from "../PatientCard/PrescriptionDialog";

function Prescriptions(props) {
    let {id} = useParams();
    const store = props.store;

    const [prescriptionsParams, setPrescriptionsParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    })
    const prescriptionStore = usePrescriptionsStore();
    const [showPrescriptionDialog, setShowPrescriptionDialog] = useState(false)
    useEffect(() => {
        store.fetchPatient({patient_id: id, doctor_id: store.doctorId});
    }, [id, store])

    useEffect(() => {
        prescriptionStore.fetchByDoctorId({
            ...prescriptionsParams,
            doctor_id: store.doctorId
        })
    }, [prescriptionStore, prescriptionsParams, store.doctorId])
    const onPageChange = (e) => {
        setPrescriptionsParams({...prescriptionsParams, first: e.first, rows: e.rows, page: e.page})
    }
    const dateBody = (rowData) => {
        return format(parseISO(rowData.date), "dd.MM.yyyy")
    }
    const beginDate = (rowData) => {
        if (rowData.beginDate)
            return format(parseISO(rowData.beginDate), "dd.MM.yyyy")
    }
    const endDate = (rowData) => {
        if (rowData.endDate)
            return format(parseISO(rowData.endDate), "dd.MM.yyyy")
    }

    return (
        <div>
            {showPrescriptionDialog && <PrescriptionDialog
                visible={showPrescriptionDialog} onHide={() => setShowPrescriptionDialog(false)}
                onSave={(data) => {
                    prescriptionStore.updatePrescription(data)
                }}
            />}
            <div className="page-header">Назначения</div>
            <div className="page-control"></div>
            <div className="page-" style={{marginBottom: "24px"}}>
                <LabeledSearchInput
                    label="Найти"
                />
            </div>
            <BlockUI blocking={prescriptionStore.isLoading}>
                {!prescriptionStore.isLoading && <div>
                    <DataTable
                        className="page-datatable"
                        value={prescriptionStore.items}
                        selection={prescriptionStore.selected}
                        selectionMode="single"
                        emptyMessage={"Назначений еще нет"}
                        onSelectionChange={(e) => {
                            if (e.value) {
                                prescriptionStore.setSelected(e.value)
                                prescriptionStore.initEditable(getSnapshot(e.value))
                                setShowPrescriptionDialog(true)
                            }
                        }}
                    >
                        <Column field={"id"} header={"Номер"}/>
                        <Column body={beginDate} header={"Дата начала"}/>
                        <Column body={endDate} header={"Дата окончания"}/>
                        <Column field={"patient.person.surnameWithInitials"} header={"Пациент"}/>
                        <Column field={"description"} header={"Состав"}/>
                        <Column body={dateBody} header={"Дата создания"}/>
                    </DataTable>
                    <Paginator
                        alwaysShow={false}
                        style={{justifyContent: "flex-end"}}
                        rows={prescriptionsParams.rows}
                        first={prescriptionsParams.first}
                        totalRecords={prescriptionStore.total}
                        onPageChange={onPageChange}
                    />
                </div>}
            </BlockUI>
        </div>
    )
}

export default observer(Prescriptions)
