import React, {useContext, useEffect, useState} from "react";
import {Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";
import {YMaps} from "@pbe/react-yandex-maps";

import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import "./App.scss";
import "../shared/scss/themes/saga/saga-blue/theme.scss"

import {AppContext} from "./AppContext";
import Login from "../pages/Login/ui/Login";
import ApiService from "../shared/api/ApiService";
import AppManager from "../pages/AdminUI/ui";
import DoctorUI from "../pages/DoctorUI/ui";
import useStore from "../hooks/useStore";
import UserMenu from "../entyties/User/ui/UserMenu";
import Register from "../pages/Login/ui/Register";
import PharmacyUI from "../pages/PharmacyUI/ui";
import ResAPIDoc from "../pages/RestApi/ResAPIDoc";
import configData from './config/config.json'
import {propisi} from "./config/global";
import RestorePass from "../pages/Login/ui/RestorePass";
import ResetPassword from "../pages/Login/ui/ResetPassword";
import RestrictedRoute from "../entyties/User/lib/RestrictedRoute";

const App = (props) => {


    const history = useHistory()
    const location = useLocation()
    const context = useContext(AppContext)
    const [isLoading, setIsLoading] = useState(false);
    const store = useStore();
    context.apiService = ApiService

    const loadUserData = (token) => {
        setIsLoading(true)
        if (sessionStorage.getItem("refresh_token"))
            context.apiService.getUser(sessionStorage.getItem("refresh_token"))
                .then(data => {
                    store.setUser(data)
                    context.user = data
                    setIsLoading(false)
                })
                .catch(e => {
                    history.push("/Login")
                })
    }

    useEffect(() => {
        loadUserData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionStorage.getItem("token")])

    if (!sessionStorage.getItem("token") &&
        (location.pathname !== "/Login") &&
        (location.pathname !== "/register") &&
        (location.pathname !== "/restore_pass") &&
        (!location.pathname.startsWith("/reset_password")) &&
        (location.pathname !== "/rest_api_doc")
    )
        history.push("/Login")

    return (

        <div className="App">
            <YMaps query={{apikey: `${configData.YAPI_KEY}`, load: "geolocation,geocode"}}>
                <Switch>
                    <Route path="/login" exact component={Login}/>
                    <Route path="/register" exact component={Register}/>
                    <Route path="/restore_pass" exact component={RestorePass}/>
                    <Route path="/reset_password/:token" component={ResetPassword}/>
                    <Route path="/" exact
                           render={
                               (props) => {
                                   if (store.users?.isAdmin) return (
                                       history.push("/admin")
                                   )
                                   if (store.users?.isDoctor) return (
                                       history.push("/doctor")
                                   )
                                   if (store.users?.isPharmacist) return (
                                       <PharmacyUI/>
                                   )
                                   if (!isLoading) return (<div style={{textAlign: "center"}}>
                                       <UserMenu/>
                                       Для Вас интерфейса пока нет!!!
                                   </div>)
                               }
                           }
                    />

                    <RestrictedRoute path="/admin" level={propisi.ADMIN}>
                        <AppManager {...props}/>
                    </RestrictedRoute>
                    <RestrictedRoute  path="/doctor" level={propisi.DOCTOR_ROLE}>
                        <DoctorUI/>
                    </RestrictedRoute>

                    <Route path="/rest_api_doc" exact component={ResAPIDoc}/>
                    <Redirect to="/"/>
                </Switch>
            </YMaps>
        </div>
    );
}

export default App;

