import React, {useEffect, useState} from "react";
import "primeflex/primeflex.css";
import {observer} from "mobx-react-lite";
import {Paginator} from "primereact/paginator";
import LabeledSearchInput from "../../../../shared/ui/LabeledSearchInput";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {getSnapshot} from "mobx-state-tree";
import {BlockUI} from "../../../../shared/ui/BlockUI";
import {useHistory} from "react-router-dom";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import PersonEditor from "../../../../entyties/Patient/ui/PatientList/PersonEditor";

const Patients = (props) => {

    const store = props.store;
    const history = useHistory()
    const [params, setParams] = useState({
        first: 0,
        rows: 10,
        page: 0
    });
    const [showPatientList, setShowPatientEditor] = useState(false)
    // const [filters, setFilters] = useState({
    //     "person.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     "person.surname": { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     "person.patronymic": { value: null, matchMode: FilterMatchMode.CONTAINS },
    // });

    useEffect(() => {
        store.fetchPatients({...params, doctor_id: store.doctorId})
    }, [params, store, store.doctorId]);

    const onChangePage = (e) => {
        setParams({...params, first: e.first, rows: e.rows, page: e.page})
    }

    // const ifNull = (value, def) =>{
    //     return value ? value : def
    // }

    // const onFilter = (e) =>{
    //     setFilters(e.filters)
    //     setParams({
    //         ...params,
    //         name:ifNull(e.filters.person?.name.value,""),
    //         surname:ifNull(e.filters.person?.surname.value,""),
    //         patronymic:ifNull(e.filters.person?.patronymic.value,""),
    //         page: 0
    //     })
    // }

    const AddPatientDialog = (props) => (
        <Dialog
            header={"Пациент"}
            visible={props.visible}
            onHide={props.onHide}
        >
            <PersonEditor onFinished={
                (data) => {
                    if (data) {
                        store.addPatient(data)
                        setShowPatientEditor(false)
                    }
                }
            }/>
        </Dialog>
    )

    return (
        <div>
            <div className="page-header">Пациенты</div>
            <div className="page-control">
                <Button className="page-control__button" label={"Добавить пациента"} onClick={() => setShowPatientEditor(true)}/>
            </div>
            <AddPatientDialog visible={showPatientList} onHide={() => setShowPatientEditor(false)}/>
            <div className="page-" style={{marginBottom: "24px"}}>
                <LabeledSearchInput
                    value={params.surname}
                    label="Найти"
                    onChange={(e) => {
                        setParams({...params, surname: e.target.value, page: 0})
                    }}
                />
            </div>
            <BlockUI blocking={store.isLoading} blockingTimeout={10}>
                <DataTable
                    value={store.patients}
                    className="page-datatable"
                    selectionMode="single"
                    emptyMessage={"Пациентов еще нет"}
                    selection={store.patient}
                    onSelectionChange={(e) => {
                        if (e.value)
                            store.setPatient(getSnapshot(e.value))
                    }}
                    onRowClick={(e) => {
                        history.push(`patients/${e.data.id}`)
                    }}
                >
                    <Column field={"person.surnameWithInitials"} header={"ФИО"}/>
                    <Column field={"person.phone"} header={"Телефон"}/>
                    <Column field={"person.email"} header={"Email"}/>
                    <Column field={"person.formatBirthDate"} header={"День рождения"} />
                    <Column field={"person.weight"} header={"Вес"}/>
                    <Column field={"person.gender"} header={"Пол"}/>
                </DataTable>
            </BlockUI>
            <Paginator
                alwaysShow={false}
                style={{justifyContent: "flex-end"}}
                rows={params.rows}
                first={params.first}
                totalRecords={store.totalPatients}
                onPageChange={onChangePage}
            />
        </div>
    )
}
export default observer(Patients)