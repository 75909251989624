import {getSnapshot, types} from "mobx-state-tree"

import {EditablePrescription, Prescription} from "./Prescription";
import ApiService from "../../../shared/api/ApiService";

export const PrescriptionsStore = types.model({
    items: types.array(Prescription),
    selected: types.safeReference(Prescription),
    isLoading: false,
    isDirty: false,
    total: types.maybe(types.number),
    error: "",
    editable: types.maybeNull(EditablePrescription)
}).actions(self=>({
    updatePatientIfNeed(patient){
        self.items = self.items.map(
            i=>i.patient.id === patient.id ? {...i, patient: getSnapshot(patient)} : i
        )
    },
    setItems(items) {
        self.items = items
    },
    setIsDirty(value){
        self.isDirty = value
    },
    fetch(params){
        self.isLoading = true;
        //ApiService.getPrescriptions(params).then(data =>{
        ApiService.getPatientPrescriptions(params).then(data =>{
            self.fetchSuccess(data)
        }).catch(e => {
            self.fetchError(e)
        })
    },
    fetchByDoctorId(params){
        self.isLoading = true;
        ApiService.getPrescriptions(params).then(data =>{
            self.fetchSuccess(data)
        }).catch(e => {
            self.fetchError(e)
        })
    },
    fetchSuccess(data) {
        if (data.content) {
            self.setItems(data.content)
        } else {
            self.items.clear()
        }
        self.total = data.totalElements
        self.error = ""
        self.isLoading = false
    },
    fetchError(error) {
        self.items.clear()
        self.error = error.message
        self.isLoading = false
    },
    setSelected(selected) {
        self.selected = selected
    },
    initEditable(item){
        if (item.id){
            self.isLoading = true
            ApiService.getPrescriptions({prescription_id:item.id}).then(data=>{
                self.fetchEditable(data)
            }).catch(e => { self.fetchError(e)})
        } else {
            self.editable = EditablePrescription.create(item)
        }
    },
    fetchEditable(data){
        if (data.content) {
            self.editable = EditablePrescription.create(data.content[0])
        } else {
            self.editable = undefined
        }
        self.error = ""
        self.isLoading = false
    },
    savePrescription(prescription){
        self.isLoading = true
        ApiService.savePrescription(prescription).then(data =>{
            self.fetchSuccess(data)
        }).catch(e => {
            self.isLoading = false
            self.fetchError(e)
        })
    },
    fetchSaveSuccess(data){
        self.updatePrescription(data)
    },
    updatePrescription(prescription){
        if (prescription){
            let item= Prescription.create(prescription)
            item.setDescription(item.content)
            let index = self.items.findIndex(i=>i.id === item.id )
            if (index>-1)
                self.items[index] = item
            else
                self.items.push(item)
            self.selected = item
            self.error = ""
            self.isLoading = false
        }
    }
}))


let _store
export const usePrescriptionsStore = ()=> {
    if (!_store) {
        _store = PrescriptionsStore.create({
            items: [],
        })
    }
    return _store;
}