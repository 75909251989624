import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import {parseISO} from "date-fns";
import {useHistory} from "react-router-dom";

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Toast} from "primereact/toast";
import {Paginator} from "primereact/paginator";

import {AppContext} from "../../../app/AppContext";
import {ClearButtonOutlined, EditButtonOutlined} from "../../../shared/ui/Styles";
import "../../../shared/scss/Resizer.css"
import {BlockUI} from "../../../shared/ui/BlockUI";
import Header from "../../../components/header";
import useStore from "../../../hooks/useStore";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import ButtonProp from "../../../shared/ui/ButtonProp";



function RolePermissions() {
    const { users } = useStore();
    const history = useHistory();

    const inputWrapperStyle = "flex flex-column gap-2 mb-2"
    const [permission, setPermission] = useState();
    const [showDialog, setShowDialog] = useState(false);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    })

    const toast = useRef(null)
    const dataTable = useRef(null);

    useEffect(() => {
        let isMounted = true
        if (isMounted)
            users.permissionsList(lazyParams);
        return () => {
            isMounted = false
        }
    }, [lazyParams])

    const createOrUpdate = useCallback((event) => {
        event.preventDefault();
        users.addPermission(permission);
        setShowDialog(false);
    }, [permission, users]);

    const actionBodyTemplate = (rowData) => {
        return (<div className="flex flex-row column-gap-0">
            <EditButtonOutlined
                type="button"
                className="p-button-text"
                style={{width: "20px", height: "20px"}}
                onClick={() => {
                    setPermission(rowData);
                    setShowDialog(true);
                }}
            />
            <ClearButtonOutlined
                style={{width: "20px", height: "20px"}}
                className="ml-4 p-button-text"
                type="button"
                onClick={() => {
                    // context.apiService.deleteUser(rowData)
                    // setItems(items.filter(i => i.id !== rowData.id))
                }}
            />
        </div>)
    }

    const updatePermission = (event) => {
        const {value, name} = event.target;

        setPermission(prevPermission => ({
            ...prevPermission,
            [name]: value,
        }));
    }

    const onChangePage = (e) => {
        setLazyParams({...lazyParams, first: e.first, rows: e.rows, page: e.page})
    }

    return (
        <>
            <Dialog
                header={permission ? "Обновить":"Создать"}
                visible={showDialog}
                onHide={() => setShowDialog(false)}>
                <form onSubmit={createOrUpdate}>
                    <div className={inputWrapperStyle}>
                        <label htmlFor="name">Название</label>
                        <InputText
                            name="name"
                            onChange={updatePermission}
                            value={permission?.name || ''}
                        />
                    </div>
                    <div className={inputWrapperStyle}>
                        <label htmlFor="inn">Slug</label>
                        <InputText
                            name="slug"
                            onChange={updatePermission}
                            value={permission?.slug || ''}
                        />
                    </div>
                    <div>
                        <ButtonProp label="Сохранить" icon="pi pi-check" type="submit"/>
                    </div>
                </form>
            </Dialog>
            <Toast ref={toast} position="top-center"/>
            <div>
                <Header title={"Модули и разрешения"} controlButtons={[{
                    label: "Добавить",
                    action: () => {
                        setPermission(null);
                        setShowDialog(true);
                    },
                    visible: true
                }]}/>
                <BlockUI blocking={users.isLoading}>
                    <DataTable
                        dataKey="id"
                        className="page-datatable"
                        ref={dataTable}
                        value={users.permissions} lazy
                        selectionMode="single"
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        totalRecords={users.total}
                        onRowClick={(e) => {
                            setPermission(e.data);
                            setShowDialog(true);
                        }}
                        onPage={onChangePage}
                    >
                        <Column field="name" header="Название"/>
                        <Column body={actionBodyTemplate} style={{width: "5rem"}}/>
                    </DataTable>
                </BlockUI>
                <Paginator
                    alwaysShow={false}
                    style={{justifyContent: "flex-end"}}
                    rows={lazyParams.rows}
                    first={lazyParams.first}
                    totalRecords={users.total}
                    onPageChange={onChangePage}
                />
            </div>
        </>
    );
}

export default observer(RolePermissions);
