import React, {useEffect, useState} from 'react'
import {Button} from "primereact/button";

function EditableRow({columns, value, rowIndex, onSave, onCancel, onRowDelete}) {

    const [current, setCurrent] = useState({...value});
    useEffect(() => {
        setCurrent({...value});
    }, [value, rowIndex])

    const createColumn = (column) => {
        if (column.rowEditor) {
            return <div style={{textAlign: 'center'}}>
                <Button icon="pi pi-check" rounded text className={"mr-3"}
                        onClick={() => {
                            if (onSave) onSave({index: rowIndex, newData: current})
                        }}
                />
                <Button icon="pi pi-times" rounded text onClick={() => {
                    if (onCancel) onCancel({index: rowIndex, data: value})
                }}/>
            </div>
        }
        if (column.rowDeleter) {
            return <div style={{textAlign: 'center'}}>
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => {if (onRowDelete) onRowDelete(value)}}
                />
            </div>
        }

        // const Editor = column.editor
        return column.editor ?
            // <column.editor
            //     name={column.field}
            //     value={current[column.field]}
            //     onChange={(data) => {
            //         setCurrent({...current, [column.field]: data})
            //     }}
            // />
            column.editor.apply(void 0, [{
                name: column.field,
                value: current[column.field],
                rowData: current,
                editorCallback: (data) => {
                    onSave({index: rowIndex, newData: {...current, [column.field]: data}})
                    setCurrent({...current, [column.field]: data})
                }
            }])
            : current[column.field]
    }


    return (
        <tr>
            {columns.map((column, index) => {
                return (
                    <td key={index}>{
                        createColumn(column)
                    }</td>
                )
            })}
        </tr>
    )
}

export default EditableRow
