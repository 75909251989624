import React, {useContext, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {clone, onPatch} from "mobx-state-tree";
import PatientList from "../../../../entyties/Patient/ui/PatientList/PatientList";
import useStore from "../../../../hooks/useStore";
import {Patient} from "../../../../entyties/Patient/model/Patient";
import RpList from "../../../../entyties/Rp/ui/RpList";
import {ItemsGridStyled} from "./ItemsGrid.styled";
import {CenteredItemStyled, CenteredLabelStyled} from "../../../../shared/ui/CenteredItems.styled";
import {InputTextarea} from "primereact/inputtextarea";
import RpItemsEditor from "./RpItemsEditor";
import {Button} from "primereact/button";
import ItemsList from "../../../../shared/ui/ItemsList";
import {AppContext} from "../../../../app/AppContext";
import {RpLecform} from "../../../../entyties/LecForm/model/RpLecform";
import {Panel} from "primereact/panel";
import NumberEditor from "../../../../shared/ui/NumberEditor";
import {Checkbox} from "primereact/checkbox";
import {Prescription} from "../../../../entyties/Prescription/model/Prescription";
import {Toast} from "primereact/toast";
import {OverlayPanel} from "primereact/overlaypanel";

function PrescriptionUI(props){

    const context = useContext(AppContext)
    const {user} = useStore();
    
    const {prescription, setPrescription, onFinished} = props
    const apiService = context.apiService
    const toast = useRef(null);

    const [showPatientList, setShowPatientList] = useState(false);
    const [showRpList, setShowRpList] = useState(false);
    const [displayLecFormList, setDisplayLecFormList] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const doctorRef = useRef();
    const patientRef = useRef();

    useEffect(() => {
        if (prescription){
            onPatch(prescription, path =>{
                setIsDirty(true)
            })
        }
    }, [prescription]);


    const onChangePrice = (e) =>{
        prescription.setPrice(e.value)
    }

    const onChangeFinished = (e) =>{
        if (!prescription.price) showError("Цена не указанна")
        else {
            prescription.setFinished(e.checked)
            onSavePrescription()
            onFinished(prescription)
        }
    }

    const onSavePrescription = () => {
        context.apiService.savePrescription(prescription).then(data=>{
            setIsDirty(false)
            setPrescription(Prescription.create(data))
            props.onSavePrescription(data)
        })
    }

    const showError = (msg) =>{
        toast.current.show({severity: 'error', summary: 'Внимание', detail: msg, life: 3000});
    }

    if (!prescription) return <></>

    return (
        <div>
            <Toast ref={toast} position="top-center"/>

            <Panel className="mr-2">
            <PatientList
                visible={showPatientList}
                onSelectItem={(data)=>{
                    if (!data.doctors) {
                        data.doctors = []
                    }
                    if (data.doctors.findIndex(i=>i.id === user.person.id)===-1){
                        data.doctors.push(clone(user.person))
                    }
                    prescription.setPatient(Patient.create(data))
                    setShowPatientList(false)
                }}
                onHide={()=>{setShowPatientList(false)}}
            />

            <RpList
                visible={showRpList}
                onHide={()=>{setShowRpList(false)}}
                onSelect={(data)=>{
                    prescription.setRpName(data.name)
                    if (data.lecForm)
                        prescription.setLecForm(clone(data.lecForm))
                    prescription.setMds(data.mds)
                    prescription.setAction(data.action)
                    prescription.clearItems()
                    data.items.forEach(i=>{
                        const item = clone(i)
                        item.setId(undefined)
                        prescription.addItem(item)
                    })
                    setShowRpList(false)
                }}
            />

            <ItemsList
                header="Формы выпуска"
                visible={displayLecFormList}
                onHide={() => setDisplayLecFormList(false)}
                getData={apiService.getRpLecForms.bind(apiService)}
                onAccept={(e) => {
                    prescription.setLecForm(RpLecform.create(e))
                    setDisplayLecFormList(false)
                }}
            />

            <div className="mt-2">
                <ItemsGridStyled>
                    <CenteredLabelStyled>Дата назначения</CenteredLabelStyled>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "auto auto auto 1fr",
                            gridRowGap: "0.5rem",
                            gridColumnGap: "0.5rem"
                        }}
                    >
                        {/*<DateEditor*/}
                        {/*    overlayPos="bottom"*/}
                        {/*    value={prescription.prescription_date}*/}
                        {/*    onChange={onSetPrescriptionDate}*/}
                        {/*    readOnly*/}
                        {/*/>*/}
                        <div>{prescription.prescription_date?.toLocaleDateString()}</div>
                        <CenteredLabelStyled>Хроническое</CenteredLabelStyled>
                        <CenteredItemStyled>
                            <Checkbox
                                checked={prescription.chronic}
                                onChange={(e) => {
                                    prescription.setChronic(e.checked)
                                }}
                                readOnly
                            />
                        </CenteredItemStyled>
                    </div>

                    <CenteredLabelStyled>Пациент</CenteredLabelStyled>
                    <div>
                        <Button onClick={(e) => {
                            patientRef.current.toggle(e)
                        }}>
                            {prescription.patient?.person.fullName}
                        </Button>
                        <div/>
                        <OverlayPanel ref={patientRef}>
                            <div>{prescription.patient?.person.fullName}</div>
                            <div>{prescription.patient?.person.phone}</div>
                        </OverlayPanel>
                    </div>

                    <CenteredLabelStyled>Врач</CenteredLabelStyled>
                    <div>
                        <Button onClick={(e) => {
                            doctorRef.current.toggle(e)
                        }}>
                            {prescription.doctor?.fullName}
                        </Button>
                        <div/>
                        <OverlayPanel ref={doctorRef}>
                            <div>{prescription.doctor?.fullName}</div>
                            <div>{prescription.doctor?.phone}</div>
                        </OverlayPanel>
                    </div>

                    <CenteredLabelStyled>Rp</CenteredLabelStyled>
                    <RpItemsEditor
                        items={prescription.items}
                        addItem={prescription.addItem}
                        removeItem={prescription.removeItem}
                        readOnly
                    />
                    <CenteredLabelStyled>M.D.S</CenteredLabelStyled>
                    <div>{prescription.mds || ""}</div>
                    <CenteredLabelStyled>Форма выпуска</CenteredLabelStyled>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "auto auto auto 1fr",
                            gridRowGap: "0.5rem",
                            gridColumnGap: "0.5rem"
                        }}
                    >
                        <div>{prescription.lecForm?.name}</div>
                        <CenteredLabelStyled>Кол-во на курс</CenteredLabelStyled>
                        <div>{prescription.dtd}</div>
                    </div>
                    <CenteredLabelStyled>Дата начала</CenteredLabelStyled>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "auto auto auto auto auto auto auto auto 1fr",
                            gridRowGap: "0.5rem",
                            gridColumnGap: "0.5rem"
                        }}>
                        <div>{prescription.begin_date?.toLocaleDateString()}</div>
                        <CenteredLabelStyled>Кол-во дней</CenteredLabelStyled>
                        <div>{prescription.daysCount}</div>
                        <CenteredLabelStyled>Дата окончания</CenteredLabelStyled>
                        <div>{prescription.end_date?.toLocaleDateString()}</div>
                    </div>

                </ItemsGridStyled>
            </div>

            </Panel>
            <Panel className="mr-2 mt-2">
                <div className="mt-2">
                    <ItemsGridStyled>
                        <CenteredLabelStyled>Способ приготовления</CenteredLabelStyled>
                        <InputTextarea value={prescription.rp?.recipe || ""} readOnly/>
                        <CenteredLabelStyled>Выдано</CenteredLabelStyled>
                        <div
                            style={{
                                display:"grid",
                                gridTemplateColumns:"auto auto auto 1fr",
                                gridRowGap:"0.5rem",
                                gridColumnGap: "0.5rem",
                                alignItems: "center"
                            }}
                        >
                            <Checkbox
                                checked={prescription.finished}
                                onChange={onChangeFinished}
                                readOnly={prescription.finished}
                            />
                            <CenteredLabelStyled>Цена</CenteredLabelStyled>
                            <NumberEditor
                                value={prescription.price || ""}
                                allowedDecimalSeparators={[".", ","]}
                                onValueChange={onChangePrice}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                suffix=" p"
                                readOnly={prescription.finished}
                            />

                        </div>
                    </ItemsGridStyled>
                    <div className="mt-2" style={{textAlign:"center"}}>
                        {/*{!isDirty &&<Button*/}
                        {/*    icon="pi pi-print"*/}
                        {/*    label = "Напечатать назначение"*/}
                        {/*    onClick={()=>{apiService.downloadPrintForm(`recipe/${prescription.id}`)}}*/}
                        {/*/>}*/}
                        {isDirty && <Button
                            label = "Сохранить изменения"
                            onClick={onSavePrescription}
                        />}
                    </div>
                </div>
            </Panel>
        </div>
    );
}

export default observer(PrescriptionUI);
