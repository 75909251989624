import React, {useEffect} from 'react'
import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";

import PrescriptionList from "../../../../entyties/Prescription/ui/PrescriptionList/PrescriptionList";
import {usePrescriptionsStore} from "../../../../entyties/Prescription/model/PrescriptionsStore";


function PatientCard(props) {
    let { id } = useParams();
    const store = props.store;
    const prescriptionStore = usePrescriptionsStore();

    useEffect(()=>{
        store.fetchPatient({patient_id:id, doctor_id:store.doctorId});
    },[id, store])

    useEffect(()=>{
        prescriptionStore.fetch({
            patient_id: id,
            doctor_id: store.doctorId
        })
    },[prescriptionStore, id, store.doctorId])

    if (!store.patient) return (<></>)
    return (
        <PrescriptionList store={store} patient_id={id} />
    )
}

export default observer(PatientCard)
