import {flow, types} from "mobx-state-tree"

import {Person} from "../../Person/model/Person";
import {Organization} from "../../Org/model/Organization";
import {Area} from "../../Area/model/Area";
import {Speciality} from "../../Speciality/model/Speciality";
import ApiService from "../../../shared/api/ApiService";

const Permission = types.model("Permission", {
    id: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    slug: types.maybeNull(types.string),
});

const RolePermission = types.model("RolePermission", {
    id: types.maybeNull(types.number),
    edit: types.maybeNull(types.boolean),
    permission: types.maybeNull(Permission),
})

export const Role = types.model("Roles", {
    id: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    permission: types.maybeNull(types.array(RolePermission)),
    level: types.maybeNull(types.integer)
})

const User = types.model("User", {
    id: types.number,
    login: types.string,
    regDate: types.maybeNull(types.string),
    person: types.maybeNull(Person),
    organization: types.maybeNull(Organization),
    roles: types.maybeNull(types.array(Role)),
    area: types.maybeNull(Area),
    speciality: types.maybeNull(Speciality),
    cert: types.maybeNull(types.string)
});

export const UserStore = types.model("UserStore", {
    roles: types.maybeNull(types.array(Role)),
    role: types.maybeNull(Role),
    users: types.maybeNull(types.array(User)),
    user: types.maybeNull(User),
    isLoading: types.maybe(types.boolean),
    permissions: types.maybeNull(types.array(Permission)),
    permission: types.maybeNull(Permission),
    total: types.maybe(types.integer)
}).views(self => ({
    get fullName() {
        return self.user.person ? `${self.user.person.surname} ${self.user.person.name}` : self.user.login
    },
    get isAdmin() {
        if (self.user?.roles)
            return self.user.roles.findIndex(i => i.name === "ROLE_ADMIN") !== -1
        return false
    },
    get isDoctor() {
        if (self.user?.roles)
            return self.user.roles.findIndex(i => i.name === "ROLE_DOCTOR") !== -1
        return false
    },
    get isPharmacist() {
        if (self.user?.roles)
            return self.user.roles.findIndex(i => i.name === "ROLE_PHARMACIST") !== -1
        return false
    },
    get reg_date() {
        return new Date(self.regDate)
    }
})).actions(self => {
    return {
        getUser(id) {
            return ApiService.get(`users/${id}`);
        },
        rolesList: flow(function* () {
            self.isLoading = true;
            const res = yield ApiService.get("roles/");
            self.roles = res.data === "" ? [] : res.data;
            self.total = res.data.totalElements;
            self.isLoading = false;
        }),
        permissionsList: flow(function* () {
            self.isLoading = true;
            const res = yield ApiService.get("permission/");
            self.permissions = res.data === "" ? [] : res.data;
            self.total = res.data.totalElements;
            self.isLoading = false;
        }),
        addPermission: flow(function* (payload) {
            const res = yield ApiService.post("permission/", payload);
            if ("id" in payload) {
                const updated = self.permissions.find(c => c.id === payload.id)
                Object.assign(updated, res.data);
            }
            self.permissionsList();
        }),
        getRole: flow(function* (id) {
            const res = yield ApiService.get(`roles/${id}`);
            self.role = res.data;
        }),
        saveRole: flow(function* (role) {
            return yield ApiService.post("roles/", role)
        })
    }
})

