import React, {useContext, useEffect, useState, useRef} from 'react';
import {Controller, useForm, useFormState} from "react-hook-form";
import {useParams} from "react-router-dom";
import {useHistory} from "react-router-dom";

import {Toast} from "primereact/toast";
import {Panel} from "primereact/panel";
import {Password} from "primereact/password";
import {InputMask} from "primereact/inputmask";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dropdown} from "primereact/dropdown";
import {Dialog} from "primereact/dialog";
import {Checkbox} from "primereact/checkbox";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {InputTextarea} from "primereact/inputtextarea";

import {AppContext} from "../../../app/AppContext";
import OrgsList from "../../Org/ui/OrgsList";
import {BorderedListItemEditor, ClearButtonOutlined} from "../../../shared/ui/Styles";
import AreasList from "../../Area/ui/AreasList";
import SpecialityList from "../../Speciality/ui/SpecialityList";
import useStore from "../../../hooks/useStore";
import ButtonProp from "../../../shared/ui/ButtonProp";
import UniversalList from "../../../shared/ui/UniversalList";
import NameFilter from "../../../shared/ui/UniversalList/NameFilter";
import Header from "../../../components/header";


function User() {
    const defaultValues = {
        login: "",
        password: "",
        person: {
            id: undefined,
            name: "",
            secondName: "",
            middleName: "",
            email: "",
            phone: ""
        },
        client: undefined,
        organization: undefined,
        roles: []
    }
    let {id} = useParams();
    const {users, clients} = useStore();
    const history = useHistory();
    const context = useContext(AppContext)
    const {
        control,
        handleSubmit,
        reset,
        watch,
        formState: {errors},
        setValue,
        getValues
    } = useForm({defaultValues})
    const {isDirty} = useFormState({control})
    const [selectedRole, setSelectedRole] = useState(undefined)
    const [roles, setRoles] = useState([])
    const [rolePresentation, setRolePresentation] = useState([])
    const [addRoleDisabled, setAddRoleDisabled] = useState(true)
    const [showPasswordDialog, setShowPasswordDialog] = useState(false)
    const [user, setUser] = useState(null)
    const [newPassword, setNewPassword] = useState("")
    const [displayOrgsList, setDisplayOrgsList] = useState(false)
    const [displaySpecialityList, setDisplaySpecialityList] = useState(false);
    const [showAreasDialog, setShowAreasDialog] = useState(false);
    const [showClientsDialog, setShowClientsDialog] = useState(false);
    const loadRoles = () => {
        context.apiService.getUserRoles().then(data => {
            setRolePresentation(data.map((i) => ({value: i.id, label: i.description})))
            setRoles(data)
        })
    }
    const userForm = useRef(null);
    const toast = useRef(null);

    const showMsg = (msg) => {
        toast.current.show({severity: 'info', detail: msg, life: 15000});
    }

    const inputWrapperStyle = "flex flex-column gap-2 mb-2"

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            if (id == 0)
                setUser(defaultValues);
            else {
                users.getUser(id).then(response => {
                    const user = response.data;
                    setUser(user)
                    reset({...user});
                });
            }
        }
        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loadRoles()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const userRoles = watch("roles")

    const changeRole = (e) => {
        setSelectedRole(e.value)
        if (userRoles) {
            setAddRoleDisabled(userRoles.find(r => {
                return r.id === e.value
            }) !== undefined)
        }
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const addRole = (role) => {
        if (!userRoles.find(i => i.id === role.id)) {
            userRoles.push(role)
            setValue("roles", userRoles, {shouldDirty: true})
            setAddRoleDisabled(true)
        }
    }

    const onSubmit = (userData) => {
        context.apiService.saveUser(userData).then(data => {
            if (id == 0) {
                id = data.id;
                setUser(data);
                history.push(`/admin/users/${id}`)
                showMsg("Пользователь создан!");
            } else
                showMsg("Пользователь сохранен!");
        })
    }

    const passwordDialogFooter = (
        <React.Fragment>
            <ButtonProp
                label="Изменить пароль"
                onClick={() => {
                    context.apiService.changePassword(user, newPassword).then(data => {
                        if (data === true) {
                            setShowPasswordDialog(false)
                        }
                    })
                }}
            />
        </React.Fragment>
    );

    const actionBodyTemplate = (rowData) => {
        return (<div>
            <ClearButtonOutlined
                style={{width: "20px", height: "20px"}}
                className="ml-4 p-button-text"
                type="button"
                onClick={() => {
                    let roles = getValues().roles.filter(i => i.id !== rowData.id)
                    setValue("roles", roles, {shouldDirty: true})
                }}
            />
        </div>)
    }

    function getTitle() {
        if (id == 0)
            return "Новый пользователь";
        else
            return user.person.name ? `${user.person.surname} ${user.person.name}` : user.login;
    }

    return (
        <>
            {user && <div className="page">
                <Toast ref={toast} position="top-right"/>
                <Header
                    title={getTitle()}
                    backLink={() => history.push("/admin/users/users")}
                    controlButtons={[
                        {
                            label: id == 0 ? "Создать" : "Сохранить",
                            action: () => userForm.current.requestSubmit(),
                            visible: isDirty
                        },
                        {
                            label: "Сбросить пароль",
                            action: () => setShowPasswordDialog(true),
                            visible: id != 0
                        },
                    ]}/>
                <form ref={userForm} onSubmit={handleSubmit(onSubmit)} style={{width: "100%"}} className="p-fluid">

                    <OrgsList
                        visible={displayOrgsList}
                        onHide={() => {
                            setDisplayOrgsList(false)
                        }}
                        onSelectItem={(data) => {
                            setValue("organization", data, {shouldDirty: true})
                            setDisplayOrgsList(false)
                        }
                        }
                    />

                    <SpecialityList
                        visible={displaySpecialityList}
                        onHide={() => {
                            setDisplaySpecialityList(false)
                        }}
                        onSelectItem={(data) => {
                            setValue("speciality", data, {shouldDirty: true})
                            setDisplaySpecialityList(false)
                        }
                        }
                    />

                    <AreasList
                        visible={showAreasDialog}
                        onHide={() => {
                            setShowAreasDialog(false)
                        }}
                        onSelectItem={(data) => {
                            setValue("area", data, {shouldDirty: true})
                            setShowAreasDialog(false)
                        }}
                    />

                    <Dialog visible={showPasswordDialog}
                            onHide={() => {
                                setShowPasswordDialog(false)
                            }}
                            footer={passwordDialogFooter}
                            style={{height: "230px"}}
                    >
                        <div><b>Новый пароль</b></div>
                        <Password
                            className="mt-1"
                            inputStyle={{width: "300px"}}
                            value={newPassword}
                            onChange={(e) => {
                                setNewPassword(e.target.value)
                            }}
                        />
                    </Dialog>

                    <UniversalList
                        header="Клиенты"
                        visible={showClientsDialog}
                        filtersTemplate={NameFilter}
                        onHide={() => setShowClientsDialog(false)}
                        onGetData={async () => {
                            return {"content": clients.clients, "totalElements": clients.total}
                        }}
                        onSelectItem={(data) => {
                            setValue("client", data, {shouldDirty: true})
                            setShowClientsDialog(false)
                        }}
                    />

                    <Panel className="page__panel page-panel">
                        <div className="page-panel__header">Информация о пользователе</div>
                        <div className="">
                            <div className={inputWrapperStyle}>
                                <label htmlFor="login">Логин</label>
                                <Controller
                                    name="login"
                                    control={control}
                                    rules={{required: 'Логин обязателен к заполнению'}}
                                    render={({field, fieldState}) => (
                                        <InputText
                                            value={field.value || ""}
                                            onChange={field.onChange}
                                            //onBlur={field.onBlur}
                                            className={classNames({
                                                'p-invalid': fieldState.invalid,
                                                "p-input-text": true
                                            })}
                                            style={{width: "100%"}}
                                        />
                                    )}
                                />
                            </div>
                            {user.id === undefined && <div className={inputWrapperStyle}>
                                <label htmlFor="password">Пароль</label>
                                <Controller
                                    name={"password"}
                                    control={control}
                                    rules={{required: 'Пароль обязателен к заполнению'}}
                                    render={({field, fieldState}) => (
                                        <Password
                                            {...field}
                                            toggleMask={true}
                                            className={classNames({'p-invalid': fieldState.invalid})}
                                        />
                                    )}/>
                                {getFormErrorMessage("password")}
                            </div>}
                            <div className={inputWrapperStyle}>
                                <label htmlFor="person.surname">Фамилия</label>
                                <Controller
                                    name="person.surname"
                                    control={control}
                                    render={({field}) => (
                                        <InputText
                                            value={field.value || ""}
                                            onChange={field.onChange}
                                            style={{width: "100%"}}
                                        />
                                    )}
                                />
                            </div>
                            <div className={inputWrapperStyle}>
                                <label htmlFor="person.name">Имя</label>
                                <Controller
                                    name="person.name"
                                    control={control}
                                    render={({field}) => (
                                        <InputText
                                            value={field.value || ""}
                                            onChange={field.onChange}
                                            style={{width: "100%"}}
                                        />
                                    )}
                                />
                            </div>
                            <div className={inputWrapperStyle}>
                                <label htmlFor="person.patronymic">Отчество</label>
                                <Controller
                                    name="person.patronymic"
                                    control={control}
                                    render={({field}) => (
                                        <InputText
                                            value={field.value || ""}
                                            onChange={field.onChange}
                                            style={{width: "100%"}}
                                        />
                                    )}
                                />
                            </div>
                            <div className={inputWrapperStyle}>
                                <label htmlFor="person.email">e - mail</label>
                                <Controller
                                    name="person.email"
                                    control={control}
                                    render={({field}) => (
                                        <InputText
                                            value={field.value || ""}
                                            onChange={field.onChange}
                                            style={{width: "100%"}}
                                        />
                                    )}
                                />
                            </div>
                            <div className={inputWrapperStyle}>
                                <label htmlFor="person.phone">Телефон</label>
                                <Controller
                                    name="person.phone"
                                    control={control}
                                    render={({field}) => (
                                        <InputMask
                                            mask="+7 (999) 999 99 99"
                                            value={field.value || ""}
                                            onChange={field.onChange}
                                            style={{width: "100%"}}
                                        />
                                    )}
                                />
                            </div>
                            <div className={inputWrapperStyle}>
                                <label htmlFor="speciality">Специальность</label>
                                <Controller
                                    name="speciality"
                                    control={control}
                                    render={({field}) => (
                                        <BorderedListItemEditor
                                            value={field.value?.name || ""}
                                            onSelect={() => {
                                                setDisplaySpecialityList(true)
                                            }}
                                            onClear={() => {
                                                setValue("speciality", undefined, {shouldDirty: true})
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className={inputWrapperStyle}>
                                <label htmlFor="cert">Сертификат</label>
                                <Controller
                                    name="cert"
                                    control={control}
                                    render={({field}) => (
                                        <InputText
                                            value={field.value || ""}
                                            onChange={field.onChange}
                                            style={{width: "100%"}}
                                        />
                                    )}
                                />
                            </div>
                            <div className={inputWrapperStyle}>
                                <label htmlFor="organization">Организация</label>
                                <Controller
                                    name="organization"
                                    control={control}
                                    render={({field}) => (
                                        <BorderedListItemEditor
                                            value={field.value?.name || ""}
                                            onSelect={() => {
                                                setDisplayOrgsList(true)
                                            }}
                                            onClear={() => {
                                                setValue("organization", undefined, {shouldDirty: true})
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className={inputWrapperStyle}>
                                <label htmlFor="area">Область</label>
                                <Controller
                                    name="area"
                                    control={control}
                                    render={({field}) => (
                                        <BorderedListItemEditor
                                            value={field.value?.name || ""}
                                            onSelect={() => {
                                                setShowAreasDialog(true)
                                            }}
                                            onClear={() => {
                                                setValue("area", undefined, {shouldDirty: true})
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className={inputWrapperStyle}>
                                <label htmlFor="client">Клиент</label>
                                <Controller
                                    name="client"
                                    control={control}
                                    render={({field}) => (
                                        <BorderedListItemEditor
                                            value={field.value?.name || ""}
                                            onSelect={() => {
                                                setShowClientsDialog(true)
                                            }}
                                            onClear={() => {
                                                setValue("client", undefined, {shouldDirty: true})
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className={inputWrapperStyle}>
                                <label htmlFor="comment">Коментарий</label>
                                <Controller
                                    name="comment"
                                    control={control}
                                    render={({field}) => (
                                        <InputTextarea
                                            value={field.value || ""}
                                            onChange={field.onChange}
                                            style={{width: "100%"}}
                                        />
                                    )}
                                />
                            </div>
                            <div className={inputWrapperStyle}>
                                <label htmlFor="approved">Доступ разрешен</label>
                                <Controller
                                    name="approved"
                                    control={control}
                                    render={({field}) => (
                                        <Checkbox
                                            checked={field.value}
                                            onChange={(e) => {
                                                setValue("approved", e.checked, {shouldDirty: true})
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </Panel>
                    <Panel className="page__panel page-panel">
                        <div className="page-panel__header">Роли пользователя</div>
                        <div>
                            <div className="mb-4">
                                <Controller
                                    name="roles"
                                    control={control}
                                    render={({field}) => (
                                        <DataTable
                                            style={{width: "100%"}}
                                            value={field.value}
                                            emptyMessage="Роли не назначены"
                                        >
                                            <Column field={"description"}/>
                                            <Column body={actionBodyTemplate} style={{width: "5rem"}}/>
                                        </DataTable>
                                    )}/>
                            </div>

                            <div className="flex flex-row justify-content-between align-items-center">
                                <Dropdown
                                    className="mr-2 w-full"
                                    style={{flex: 6}}
                                    value={selectedRole}
                                    options={rolePresentation}
                                    placeholder={"Роль"}
                                    onChange={changeRole}
                                />
                                <ButtonProp
                                    style={{flex: 1}}
                                    label="Добавить"
                                    icon="pi pi-plus"
                                    disabled={addRoleDisabled || !selectedRole}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        addRole(roles.find(i => i.id === selectedRole))
                                    }}
                                />
                            </div>
                        </div>
                    </Panel>
                </form>
            </div>
            }

        </>)
        ;
}

export default User;
