import React, {useEffect, useState} from 'react';
import {Dialog} from "primereact/dialog";
import PropTypes from "prop-types";
import {DataTable} from "primereact/datatable";

import 'react-block-ui/style.css';
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {ClearButtonOutlined, EditButtonOutlined} from "../Styles";
import styled from "styled-components";
import {BlockUI} from "../BlockUI";

const DialogStyled = styled(Dialog)`
    .p-dialog-content{
      overflow: visible;  
    }
`
function UniversalList(props) {

    const Filters = props.filtersTemplate;
    const RowViewer = props.rowViewerTemplate;
    const Editor = props.rowEditorTemplate;
    const initialValue = props.initialValue;
    const fullPageEditor = props.fullPageEditor;
    const emptyMessage = props.emptyMessage;

    const addRow = props.onAddRow || false;
    const editRow = props.onEditRow || false;
    const deleteRow = props.onDeleteRow || false;

    const onGetData = props.onGetData;
    const onSelectItem = (data)=>{setLocked(true); if (props.onSelectItem) props.onSelectItem(data);}

    const [params, setParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        name: initialValue || "",
    });

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(undefined);
    const [totalElements, setTotalElements] = useState(0);
    const [editing, setEditing] = useState(false);
    const [locked, setLocked] = useState(true);
    const [displayConfirmationDialog, setDisplayConfirmationDialog] = useState(false);
    const [editingRow, setEditingRow] = useState({});

    let columns = undefined;
    if (props.columns){
        columns = props.columns.map((column) => {
            return <Column key={column.id} field={column.field} header={column.header} body={column.body}/>
        })
    }

    const deleteRowFooter = (
        <React.Fragment>
            <Button type="button" label="Нет" autoFocus={true} icon="pi pi-times" className="p-button-success" onClick={() => {setDisplayConfirmationDialog(false)}} />
            <Button type="button" label="Да" icon="pi pi-check" className="p-button-danger" onClick={()=>{deleteRow(selected)}}/>
        </React.Fragment>
    );

    const actionBodyTemplate = (rowData)=> {
        return(<div style={{textAlign:"right"}}>
            {Editor && editRow && <EditButtonOutlined
                type="button"
                className="p-button-text"
                style={{width:"20px",height:"20px"}}
                onClick = {()=>{
                    setEditingRow({...rowData})
                    setEditing(true)
                }}
            />}

            {deleteRow && <ClearButtonOutlined
                style={{width:"20px",height:"20px"}}
                className="ml-4 p-button-text"
                type="button"
                onClick = {()=>{
                    setDisplayConfirmationDialog(true)
                }}
            />}
        </div>)
    }

    const paginatorRight = ()=>{
    if (editRow || deleteRow)
    return <Button
        type="button"
        icon={locked ? "pi pi-lock": "pi pi-unlock"}
        className="p-button-danger p-button-outlined"
        onClick={()=>setLocked(!locked)}
        />
    }

    const paginatorLeft = () =>{
        if (addRow && Editor)
            return <div>
                <Button
                    type="button"
                    label="Добавить запись"
                    icon="pi pi-plus"
                    onClick={()=>{
                        setEditingRow(undefined)
                        setEditing(true)
                    }}
                />
            </div>
        return <div></div>
    }

    const onSelect = (e) =>{
        if (RowViewer){
            setSelected(e.value);
        } else if (onSelectItem){
            onSelectItem(e.value);
        }
    }

    const onHide = () =>{
        setLocked(true)
        if (!editing)
            props.onHide()
        else
            setEditing(false)
    }

    const onPage = (event) => {
        let _params = { ...params, ...event };
        setParams(_params);
    }

    const onEditingFinished = (props) => {
        setEditing(false)
        setLocked(true)

        if (selected && props && selected.id === props.id) setSelected(props)
        if (props){
            if (items.find(i=>i.id === props.id)) {
                setItems(items.map(i => {
                    if (i.id === props.id) return props; else return i
                }))
            } else {
                items.push(props)
                setItems([...items])
            }
        }
    }

    useEffect(() => {
        let isMounted = true;
        const loadLazyData = () => {
            setLoading(true);
            onGetData(params).then(data => {
                if (isMounted){
                    setTotalElements(data?.totalElements);
                    setItems(data?.content);
                    setLoading(false);
                }
            }).catch(e=>{
                setLoading(false);
            });
        }
        if (onGetData !== undefined && props.visible){
            loadLazyData();
        }
        return ()=> isMounted = false;
    },[onGetData, params, props])

    return (
        <div>
            <DialogStyled
                visible={props.visible}
                onHide={onHide}
                style={{ width: '80%'}}
            >
                {!editing &&<div className="container p-1">
                    {Filters && <Filters params={params} setParams={setParams}/>}
                    {!Filters &&
                    <div className="p-input-icon-left p-d-block" >
                        <i className="pi pi-search" />
                        <InputText value={params.name} className=""
                                   onChange ={(e) => {setParams({...params, name:e.target.value})}}
                                   placeholder="Поиск" autoFocus/>
                    </div>}
                </div>}

                <Dialog
                    visible={displayConfirmationDialog}
                    style={{ width: '450px' }}
                    header="Внимание" modal
                    footer={deleteRowFooter}
                    onHide={() =>{setDisplayConfirmationDialog(false)}}
                >
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                        {selected && <span>Вы действительно хотите удалить запись ?</span>}
                    </div>
                </Dialog>

                <BlockUI blocking={loading}>
                    { !(editing && fullPageEditor) && <DataTable
                        value={items} lazy
                        dataKey="id"
                        size="small"
                        selectionMode="single"
                        selection={selected}
                        onSelectionChange={onSelect}
                        paginator first={params.first} rows={params.rows} totalRecords={totalElements}
                        paginatorRight={paginatorRight} paginatorLeft={paginatorLeft}
                        onPage={onPage}
                        emptyMessage={emptyMessage || "Нет записей"}
                    >
                        {columns ||<Column field="name" header="Наименование"/>}
                        { ((editRow || deleteRow) && !locked) && <Column body={actionBodyTemplate} style={{width:"5rem"}}/>}
                    </DataTable>}
                </BlockUI>
                {!editing && RowViewer && selected && <div className="mt-2">
                    <RowViewer row={selected}/>
                    <div className="mt-2" style={{textAlign:"center"}}>
                        <Button type="button" label="Выбрать" onClick={()=>{onSelectItem(selected)}} />
                    </div>
                </div>}
                {editing && Editor &&
                    <Editor row={editingRow} onFinished={onEditingFinished} />
                }

            </DialogStyled>
        </div>
    );
}

UniversalList.propTypes = {
    filtersTemplate: PropTypes.oneOfType([PropTypes.element,PropTypes.func]),
    rowViewerTemplate: PropTypes.oneOfType([PropTypes.element,PropTypes.func]),
    rowEditorTemplate: PropTypes.oneOfType([PropTypes.element,PropTypes.func]),
    onAddRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onDeleteRow: PropTypes.func,
    columns: PropTypes.array,
    onHide: PropTypes.func.isRequired,
    onGetData: PropTypes.func.isRequired,
    onSelectItem: PropTypes.func.isRequired,
    fullPageEditor: PropTypes.bool,
    visible: PropTypes.bool,
    emptyMessage: PropTypes.string
}

export default UniversalList;
