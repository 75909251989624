import React from "react";
import PropTypes from "prop-types";
import {Button} from "primereact/button";


function ButtonProp(props) {
    const {className, label, onClick, icon, severity, isMobile, style, disabled} = props;

    return (
        <Button
            disabled={disabled}
            style={style}
            icon={icon}
            severity={severity}
            className={`button-prop ${className}`}
            label={isMobile ? "" : label}
            rounded={isMobile}
            onClick={onClick}/>
    );
}

ButtonProp.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    isMobile: PropTypes.bool
}

export default ButtonProp;