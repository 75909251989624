import {flow, types} from "mobx-state-tree"
import ApiService from "../shared/api/ApiService";


const Organization = types.model("Organization", {
    id: types.maybeNull(types.integer),
    name: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    coords: types.maybeNull(types.array(types.number))
})

const OrganizationStore = types.model("OrganizationStore", {
    active: types.safeReference(Organization),
    organizations: types.array(Organization),
    isLoading: types.maybe(types.boolean),
    total: types.maybe(types.integer),
}).actions(self => ({
    selectOrganizationsList: flow(function* (params) {
        self.isLoading = true;
        const res = yield ApiService.get("medical_orgs", params);
        self.organizations = res.data.content === "" ? [] : res.data.content;
        self.total = res.data.totalElements;
        self.isLoading = false;
    }),
    selectOrganization(id) {
        self.active = id;
    },
    getOrganization: flow(function* (id) {
        const organization = yield ApiService.get(`medical_orgs/${id}`);
        return organization.data;
    }),
    save: flow(function* (payload) {
        const organization = yield ApiService.post(`medical_orgs`, payload);
        return organization.data;
    })
}));

export default OrganizationStore;