import React, {useState, useCallback} from "react";
import {toJS} from "mobx";

import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";

import LabeledSearchInput from "../../../shared/ui/LabeledSearchInput";
import useStore from "../../../hooks/useStore";
import ButtonProp from "../../../shared/ui/ButtonProp";
import {BlockUI} from "../../../shared/ui/BlockUI";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Paginator} from "primereact/paginator";
import {observer} from "mobx-react-lite";
import {ClearButtonOutlined, EditButtonOutlined} from "../../../shared/ui/Styles";
import rowItem from "../../../shared/ui/RowItem";
import Header from "../../../components/header";


function ClientList() {
    const {clients} = useStore();
    const [clientState, setClientState] = useState();
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [displayConfirmationDialog, setDisplayConfirmationDialog] = useState(false);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    })

    const onChangePage = (e) => {
        setLazyParams({...lazyParams, first: e.first, rows: e.rows, page: e.page})
    }

    const updateClientState = (event) => {
        const {value, name} = event.target;

        setClientState(prevClientState => ({
            ...prevClientState,
            [name]: value,
        }));
    }

    const createClient = useCallback((event) => {
        event.preventDefault();
        clients.addClient(clientState);
        setShowCreateDialog(false);
    }, [clientState, clients]);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex flex-row column-gap-0">
                <EditButtonOutlined
                    type="button"
                    className="p-button-text"
                    style={{width: "20px", height: "20px"}}
                    onClick={() => {
                        setClientState({...rowData})
                        setShowCreateDialog(true)
                    }}
                />
                <ClearButtonOutlined
                    style={{width: "20px", height: "20px"}}
                    className="ml-4 p-button-text"
                    type="button"
                    onClick={() => {
                        setClientState({...rowData})
                        setDisplayConfirmationDialog(true)
                    }}
                />
            </div>)
    }

    const inputWrapperStyle = "flex flex-column gap-2 mb-2"

    return (
        <>
            <Dialog
                visible={displayConfirmationDialog}
                style={{width: '450px'}}
                header="Внимание" modal
                onHide={() => {
                    setDisplayConfirmationDialog(false)
                }}
            >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                    <span>Вы действительно хотите удалить запись ?</span>
                    <div className="flex flex-row justify-content-between mt-4">
                        <Button type="button"
                                label="Нет"
                                autoFocus={true}
                                icon="pi pi-times"
                                className="p-button-success"
                                onClick={() => {
                                    setDisplayConfirmationDialog(false);
                                }}/>
                        <Button
                            type="button"
                            label="Да"
                            icon="pi pi-check"
                            className="p-button-danger"
                            onClick={() => {
                                clients.removeClient(clientState.id);
                                setDisplayConfirmationDialog(false);
                            }}/>
                    </div>
                </div>
            </Dialog>
            <Dialog
                header="Добавление клиента"
                visible={showCreateDialog}
                onHide={() => setShowCreateDialog(false)}>
                <form onSubmit={createClient}>
                    <div className={inputWrapperStyle}>
                        <label htmlFor="name">Название</label>
                        <InputText
                            name="name"
                            onChange={updateClientState}
                            value={clientState?.name || ''}
                        />
                    </div>
                    <div className={inputWrapperStyle}>
                        <label htmlFor="inn">ИНН</label>
                        <InputText
                            name="inn"
                            onChange={updateClientState}
                            value={clientState?.inn || ''}
                        />
                    </div>
                    <div>
                        <ButtonProp label="Сохранить" icon="pi pi-check" type="submit"/>
                    </div>
                </form>
            </Dialog>
            <div>
                <Header title={"Клиенты"} controlButtons={[{
                    label: "Добавить",
                    action: () => setShowCreateDialog(true),
                    visible: true
                }]}/>
                <div className="page-" style={{marginBottom: "24px"}}>
                    <LabeledSearchInput
                        value={lazyParams.login || ""}
                        label="Найти"
                        onChange={(e) => {
                            setLazyParams({...lazyParams, page: 0, login: e.target.value})
                        }}
                    />
                </div>
                <BlockUI blocking={clients.isLoading}>
                    <DataTable
                        className="page-datatable"
                        dataKey="id"
                        value={toJS(clients.clients)}
                        lazy
                        selectionMode="single"
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        totalRecords={clients.total}
                        onRowClick={(e) => {

                        }}
                        onPage={onChangePage}
                    >
                        <Column field="name" header="Название"/>
                        <Column field="inn" header="ИНН"/>
                        <Column body={actionBodyTemplate} style={{width: "5rem"}}/>
                    </DataTable>
                    <Paginator
                        alwaysShow={false}
                        style={{justifyContent: "flex-end"}}
                        rows={lazyParams.rows}
                        first={lazyParams.first}
                        totalRecords={clients.total}
                        onPageChange={onChangePage}
                    />
                </BlockUI>
            </div>
        </>
    )
}

export default observer(ClientList);