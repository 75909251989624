import React, {useContext, useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import {parseISO} from "date-fns";
import {useHistory} from "react-router-dom";

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Toast} from "primereact/toast";
import {Paginator} from "primereact/paginator";

import {AppContext} from "../../../app/AppContext";
import {ClearButtonOutlined} from "../../../shared/ui/Styles";
import "../../../shared/scss/Resizer.css"
import {BlockUI} from "../../../shared/ui/BlockUI";
import LabeledSearchInput from "../../../shared/ui/LabeledSearchInput";
import Header from "../../../components/header";



function Users() {
    const history = useHistory();
    const context = useContext(AppContext);

    const [isLoading, setIsLoading] = useState(false)
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        login: undefined
    })
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const toast = useRef(null)
    const dataTable = useRef(null);

    useEffect(() => {
        let isMounted = true
        const loadData = () => {
            setIsLoading(true)
            context.apiService.getUsers(lazyParams).then(data => {
                if (isMounted) {
                    setItems(data.content === undefined ? [] : data.content)
                    setTotal(data.totalElements)
                    setIsLoading(false)
                }
            })
        }
        loadData()
        return () => {
            isMounted = false
        }
    }, [context.apiService, lazyParams])

    const actionBodyTemplate = (rowData) => {
        return (<div>
            <ClearButtonOutlined
                style={{width: "20px", height: "20px"}}
                className="ml-4 p-button-text"
                type="button"
                onClick={() => {
                    context.apiService.deleteUser(rowData)
                    setItems(items.filter(i => i.id !== rowData.id))
                }}
            />
        </div>)
    }

    const formatDate = (value) => {
        return value.toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        })
    };
    const dateBodyTemplate = (rowData) => {
        if (rowData.regDate === null) return null
        return formatDate(parseISO(rowData.regDate));
    };

    const onChangePage = (e) => {
        setLazyParams({...lazyParams, first: e.first, rows: e.rows, page: e.page})
    }

    return (
        <>
            <Toast ref={toast} position="top-center"/>
            <div>
                <Header title={"Пользователи"} controlButtons={[{
                    label: "Добавить пользователя",
                    action: () => history.push("users/0"),
                    visible: true
                }]}/>
                <div className="page-" style={{marginBottom: "24px"}}>
                    <LabeledSearchInput
                        value={lazyParams.login || ""}
                        label="Найти"
                        onChange={(e) => {
                            setLazyParams({...lazyParams, page: 0, login: e.target.value})
                        }}
                    />
                </div>
                <BlockUI blocking={isLoading}>
                    <DataTable
                        className="page-datatable"
                        ref={dataTable}
                        value={items} lazy
                        selectionMode="single"
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        totalRecords={total}
                        onRowClick={(e) => {
                            history.push(`users/${e.data.id}`)
                        }}
                        onPage={onChangePage}
                    >
                        <Column field="login" header="login"/>
                        <Column field="person.surname" header="Фамилия"/>
                        <Column field="person.name" header="Имя"/>
                        <Column field="person.patronymic" header="Отчество"/>
                        <Column dataType="date" body={dateBodyTemplate} header="Дата регистрации"/>
                        <Column
                            header="Вход разрешен"
                            body={(row) => (<>{row.approved && <span className="pi pi-check"/>}</>)}
                            field="approved"/>
                        <Column
                            field="person.roles"
                            body={(rowData) => rowData.roles.map(item => item.description)}
                            header="Роли"/>
                        <Column body={actionBodyTemplate} style={{width: "5rem"}}/>
                    </DataTable>
                </BlockUI>
                <Paginator
                    alwaysShow={false}
                    style={{justifyContent: "flex-end"}}
                    rows={lazyParams.rows}
                    first={lazyParams.first}
                    totalRecords={total}
                    onPageChange={onChangePage}
                />
            </div>
        </>
    );
}

export default observer(Users);
