import React, {useEffect, useState} from 'react'
import {useComponentStore} from "../../model/ComponentsStore";
import {FilterMatchMode} from "primereact/api";
import {BlockUI} from "../../../../shared/ui/BlockUI";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Paginator} from "primereact/paginator";
import {observer} from "mobx-react-lite";
import EditorDialog from "./EditorDialog";
import {Button} from "primereact/button";
import {getSnapshot} from "mobx-state-tree";
import {Dialog} from "primereact/dialog";
import {ClearButtonOutlined, EditButtonOutlined} from "../../../../shared/ui/Styles";
import {Toast} from "primereact/toast";
import Header from "../../../../components/header";

function Components()
{
    const store = useComponentStore()
    const [params, setParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    })

    const [filters, setFilters] = useState({
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ru_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        en_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const [editorVisible, setEditorVisible] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [component, setComponent] = useState()

    useEffect(()=>{
        let isMounted = true
        const loadData = () =>{
            if (isMounted)
                store.fetch({...params})
        }
        loadData()
        return ()=>{isMounted=false}
    },[store, params])

    const onChangePage = (e)=>{
        setParams({...params, first:e.first, rows:e.rows, page: e.page })
    }

    const ifNull = (value, def) =>{
        return value ? value : def
    }

    const onFilter = (e) =>{
        setFilters(e.filters)
        setParams({
            ...params,
            name:ifNull(e.filters.name.value,""),
            ru_name:ifNull(e.filters.ru_name.value,""),
            en_name:ifNull(e.filters.en_name.value,""),
        })
    }

    const onSaveComponent =(component)=>{
        store.saveAndSelect(component)
    }

    const editTemplate = (rowData, options) => {
        return <EditButtonOutlined
            style={{width:"20px",height:"20px"}}
            type="button"
            icon='pi pi-pencil'
            className="p-button-sm p-button-text"
            onClick={()=>{setComponent(getSnapshot(rowData)); setEditorVisible(true)}}
        />;
    };
    const deleteTemplate = (rowData, options) => {
        return <ClearButtonOutlined
            style={{width:"20px",height:"20px"}}
            type="button"
            icon='pi pi-trash'
            severity="danger"
            className="p-button-sm p-button-text"
            onClick={()=>{setComponent(getSnapshot(rowData)); setShowDeleteDialog(true)}}
        />;
    };

    const deleteDialogFooterContent = (
        <div>
            <Button label="Да" icon="pi pi-check"  severity="danger" onClick={() =>{
                store.deleteComponent(component)
                setShowDeleteDialog(false)
            }} />
            <Button label="Нет" icon="pi pi-check" onClick={() => setShowDeleteDialog(false)} autoFocus />
        </div>
    );

    return (
        <div className="page">
            <EditorDialog
                visible={editorVisible}
                setVisible={setEditorVisible}
                component={component}
                onSaveComponent={onSaveComponent}
            />

            <Dialog
                header="ВНИМАНИЕ"
                visible={showDeleteDialog}
                onHide={()=>{setShowDeleteDialog(false)}}
                footer={deleteDialogFooterContent}
            >
                <div>
                    Удалить компонент:
                </div>
                <div>
                    <strong>{component?.name}?</strong>
                </div>
            </Dialog>
            <Header
                title="Компоненты"
                controlButtons = {[
                    {
                        label: "Добавить",
                        action: () => {
                            setComponent({})
                            setEditorVisible(true)
                        },
                        visible: true
                    }
                ]}
            />
            <BlockUI blocking={store.isLoading}>
                <DataTable
                    value={store.items}
                    filters={filters}
                    filterDisplay="row"
                    selection={store.selected}
                    selectionMode="single"
                    onSelectionChange={e => {
                        if (e.value) store.setSelected(e.value);
                    }}
                    onFilter={onFilter}
                >
                    <Column filter header="Латынь" field="name"/>
                    <Column filter header="Русский" field="ru_name"/>
                    <Column filter header="Английский" field="en_name"/>
                    <Column style={{ flex: '0 0 4rem', width:"10px" }} body={editTemplate}></Column>
                    <Column style={{ flex: '0 0 4rem', width:"10px" }} body={deleteTemplate}></Column>
                </DataTable>
                <Paginator
                    alwaysShow={false}
                    style={{justifyContent: "flex-end"}}
                    rows={params.rows}
                    first={params.first}
                    totalRecords={store.total}
                    onPageChange={onChangePage}
                />
            </BlockUI>
        </div>
    )}

export default observer(Components)
