import React from 'react';
import {InputText} from "primereact/inputtext";
import PropTypes from "prop-types";

function LabeledSearchInput(props) {
    const {value, label, onChange, style, autoFocus} = props
    return (
        <span style={style} className="p-input-icon-left p-float-label input-prop">
            <i className="pi pi-search" />
            <InputText value={value} onChange={onChange} autoFocus={autoFocus} />
            <label>{label}</label>
        </span>
    );
}

LabeledSearchInput.propTypes = {
    value : PropTypes.any,
    label: PropTypes.string,
    onChange: PropTypes.func,
    style: PropTypes.object,
    autoFocus: PropTypes.bool
}

export default LabeledSearchInput;
