import {clone, getSnapshot, types} from "mobx-state-tree";
import {Prescription} from "../../../entyties/Prescription/model/Prescription";
import ApiService from "../../../shared/api/ApiService";
import {Patient} from "../../../entyties/Patient/model/Patient";

export const DoctorUIState = types.model({
    prescriptions: types.array(Prescription),
    prescription: types.maybe(Prescription),
    patients: types.array(Patient),
    patient: types.maybeNull(Patient),
    isLoading: false,
    isDirty:false,
    totalPrescriptions: types.maybeNull(types.integer),
    totalPatients: types.maybeNull(types.integer),
    error:"",
    doctorId: types.maybeNull(types.integer)
}).actions(self => ({
    addPatient(data){
        let patient= Patient.create(data)
        let index = self.patients.findIndex(i=>i.id === patient.id )
        if (index>-1)
            self.patients[index] = patient
        else
            self.patients.push(patient)
        self.selected = getSnapshot(patient);
    },
    setPatient(patient){
        self.patient = patient;
    },
    setDoctorId(doctorId){
      self.doctorId = doctorId
    },
    addPrescription(prescription){
        if(self.prescriptions.findIndex(i=>i.id===prescription.id)===-1)
            self.prescriptions.push(prescription)
        else
            self.prescriptions = self.prescriptions.map(i => i.id === prescription.id ? prescription : i)
        if (self.prescription.id === prescription.id)
            self.prescription = clone(prescription)
    },
    removePrescription(prescription){
        self.prescriptions = self.prescriptions.filter(i=>i.id!==prescription.id)
    },
    setPrescription(prescription){
        self.prescription = clone(prescription)
    },
    fetchPrescriptions(params){
        self.isLoading = true;
        // ApiService.getPrescriptions(params).then(data =>{
        ApiService.getPatientPrescriptions(params).then(data =>{
            self.fetchPrescriptionsSuccess(data)
        }).catch(e =>{
            self.fetchError(e)
            console.log(e)
        })
    },
    fetchPrescriptionsSuccess(data){
        self.isLoading = false
        self.prescriptions=[]
        if (data.content){
            data.content.forEach(i=>{
                self.prescriptions.push(Prescription.create(i))
            })
        }
        self.totalPrescriptions = data.totalElements
    },
    fetchError(error){
        self.isLoading = false
        self.error = error.message
    },
    fetchPatients(params){
        self.isLoading = true;
        ApiService.getPatients(params).then(data => {
            self.fetchPatientsSuccess(data)
        }).catch(e =>{
            self.fetchError(e)
            console.log(e)
        })
    },
    fetchPatientsSuccess(data){
        self.isLoading = false
        self.patients = []
        if (data.content){
            data.content.forEach(i => {
                self.patients.push(Patient.create(i))
            })
        }
        self.totalPatients = data.totalElements
    },
    fetchPatient(params){
        self.isLoading = true;
        ApiService.getPatients(params).then(data =>{
            self.fetchPatientSuccess(data)
        }).catch(e =>{
            self.fetchError(e)
            console.log(e)
        })
    },
    fetchPatientSuccess(data){
        self.isLoading = false
        self.patient=undefined
        if (data.content){
            self.patient = data.content[0]
        }
    },
    setIsDirty(value){
        self.isDirty = value
    }
}))
