import React, {useContext, useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Toast} from "primereact/toast";
import {Paginator} from "primereact/paginator";

import "../../../../shared/scss/Resizer.css"
import {AppContext} from "../../../../app/AppContext";
import 'react-block-ui/style.css';
import {BlockUI} from "../../../../shared/ui/BlockUI";
import {ClearButtonOutlined} from "../../../../shared/ui/Styles";
import Header from "../../../../components/header";
import {useHistory} from "react-router-dom";
import useStore from "../../../../hooks/useStore";


function RpList(props) {
    const history = useHistory();
    const {rps} = useStore();
    const [items, setItems] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const toast = useRef(null);

    const context = useContext(AppContext)

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        name: "",
        sort: "name"
    })
    const [selectedRp, setSelectedRp] = useState(undefined);

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            rps.selectRpList(lazyParams);
        }
        return () => {
            isMounted = false
        }
        // if (!selectedRp) {
        //     setRp(undefined)
        //     return
        // }
        //
        // if (!selectedRp.id) {
        //     setRp(selectedRp)
        //     return
        // }
        //
        // let isMounted = true
        // const loadData = () => {
        //     setIsDataLoading(true)
        //     context.apiService.getRpByID(selectedRp.id).then(data => {
        //         if (isMounted) {
        //             if (data) {
        //                 setRp(data)
        //             } else {
        //                 setRp(undefined)
        //             }
        //             setIsDataLoading(false)
        //         }
        //     })
        // }
        // loadData()
    }, [lazyParams]);

    // useEffect(() => {
    //     let isMounted = true
    //     const loadData = () => {
    //         setIsLoading(true)
    //         context.apiService.getRpNames(lazyParams).then(data => {
    //             if (isMounted) {
    //                 if (data) {
    //                     setItems(data.content === undefined ? [] : data.content)
    //                     setTotal(data.totalElements)
    //                 } else {
    //                     setItems(undefined)
    //                     setTotal(0)
    //                 }
    //                 setIsLoading(false)
    //             }
    //         })
    //     }
    //     loadData()
    //     return () => {
    //         isMounted = false
    //     }
    //
    // }, [context.apiService, lazyParams])

    const createRp = () => {
        // setSelectedRp({...defaultValues})
    }

    const saveRp = (e) => {
        // setIsSaving(true)
        // context.apiService.saveRp(e).then(data => {
        //     if (items.find(i => i.id === data.id))
        //         setItems(items.map(i => {
        //             if (i.id === data.id) return data; else return i
        //         }))
        //     else {
        //         items.push(data)
        //         setItems([...items])
        //     }
        //     setSelectedRp(data)
        //     setIsSaving(false)
        // }).catch(data => {
        //     setIsSaving(false)
        //     showError(data)
        // })
    }

    const onChangePage = (e) => {
        setLazyParams({...lazyParams, first: e.first, rows: e.rows, page: e.page})
    }

    const deleteDialogFooterContent = (
        <div>
            <Button label="Да" icon="pi pi-check" severity="danger" onClick={() => {
                context.apiService.deleteRp(selectedRp).then(() => {
                    setItems(items.filter(i => i.id !== selectedRp.id))
                    setSelectedRp(undefined)
                })
                setShowDeleteDialog(false)
            }}/>
            <Button label="Нет" icon="pi pi-check" onClick={() => setShowDeleteDialog(false)} autoFocus/>
        </div>
    );

    const showError = (data) => {
        toast.current.show({
            severity: 'error',
            summary: 'Внимание',
            detail: `Произошла ошибка во время сохранения записи! ${data}`,
            life: 3000
        });
    }

    const deleteTemplate = (rowData, options) => {
        return <ClearButtonOutlined
            style={{width: "20px", height: "20px"}}
            type="button"
            icon='pi pi-trash'
            severity="danger"
            className="p-button-sm p-button-text"
            onClick={() => {
                setSelectedRp(rowData);
                setShowDeleteDialog(true)
            }}
        />;
    };

    return (
        <>
            {/*<Panel>*/}
            {/*    <SearchInput*/}
            {/*        value={lazyParams.name}*/}
            {/*        style={{width: "100%"}}*/}
            {/*        onChange={(e) => {*/}
            {/*            setLazyParams({...lazyParams, name: e.target.value, page: 0})*/}
            {/*        }}*/}
            {/*    />*/}
            {/*    <div className="mt-1 mb-1" style={{display: "flex"}}>*/}
            {/*        <div className="ml-2">Сортировать по:</div>*/}
            {/*        <div className="ml-2">*/}
            {/*            <RadioButton*/}
            {/*                inputId="sort1" name="sort" value="name"*/}
            {/*                onChange={(e) => setLazyParams({...lazyParams, sort: e.value})}*/}
            {/*                checked={lazyParams.sort === 'name'}*/}
            {/*            />*/}
            {/*            <label className="ml-2" htmlFor="sort1">названию</label>*/}
            {/*        </div>*/}
            {/*        <div className="ml-2">*/}
            {/*            <RadioButton*/}
            {/*                inputId="sort2" name="sort" value="id,desc"*/}
            {/*                onChange={(e) => setLazyParams({...lazyParams, sort: e.value})}*/}
            {/*                checked={lazyParams.sort === "id,desc"}*/}
            {/*            />*/}
            {/*            <label className="ml-2" htmlFor="sort1">порядку добавления</label>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Panel>*/}
            <Toast ref={toast} position="top-center"/>
            {showDeleteDialog && <Dialog
                header="ВНИМАНИЕ"
                visible={showDeleteDialog}
                onHide={() => {
                    setShowDeleteDialog(false)
                }}
                footer={deleteDialogFooterContent}
            >
                <div>
                    Удалить компонент:
                </div>
                <div>
                    <strong>{selectedRp?.name}?</strong>
                </div>
            </Dialog>}
            <div>
                <Header title={"Прописи"} controlButtons={[{
                    label: "Добавить",
                    action: () => history.push("/admin/rp/0"),
                    visible: true
                }]}/>
                <BlockUI blocking={rps.isLoading}>
                    <DataTable
                        className="m-2"
                        value={rps.rps}
                        emptyMessage="Нет записей"
                        selectionMode="single"
                        selection={selectedRp}
                        onRowClick={(e) => {
                            history.push(`/admin/rp/${e.data.id}`)
                        }}
                    >
                        <Column field="name" header="Наименование"/>
                        <Column style={{width: "30px"}} body={deleteTemplate}></Column>
                    </DataTable>
                    <Paginator
                        alwaysShow={false}
                        style={{justifyContent: "flex-end"}}
                        rows={lazyParams.rows}
                        first={lazyParams.first}
                        totalRecords={rps.total}
                        onPageChange={onChangePage}
                    />
                </BlockUI>
            </div>
        </>
    );
}

export default observer(RpList);
