import React, {useEffect, useState} from 'react'

import {getSnapshot} from "mobx-state-tree";
import {observer} from "mobx-react-lite";

import {FilterMatchMode} from "primereact/api";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Paginator} from "primereact/paginator";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";

import {BlockUI} from "../../../../shared/ui/BlockUI";
import EditorDialog from "./EditorDialog";
import {useRpTypeStore} from "../../model/RpTypeStore";
import {ClearButtonOutlined, EditButtonOutlined} from "../../../../shared/ui/Styles";
import Header from "../../../../components/header";

function RpType() {
    const store = useRpTypeStore()
    const [params, setParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    })

    const [filters, setFilters] = useState({
        name: {value: null, matchMode: FilterMatchMode.CONTAINS},
    });

    const [editorVisible, setEditorVisible] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [rpType, setRpType] = useState()

    useEffect(() => {
        let isMounted = true
        const loadData = () => {
            if (isMounted)
                store.fetch({...params})
        }
        loadData()
        return () => {
            isMounted = false
        }
    }, [store, params])

    const onChangePage = (e) => {
        setParams({...params, first: e.first, rows: e.rows, page: e.page})
    }

    const ifNull = (value, def) => {
        return value ? value : def
    }

    const onFilter = (e) => {
        setFilters(e.filters)
        setParams({
            ...params,
            name: ifNull(e.filters.name.value, ""),
        })
    }

    const onSaveRpType = (rpType) => {
        store.saveAndSelect(rpType)
    }

    const editTemplate = (rowData, options) => {
        return <EditButtonOutlined
            style={{width: "20px", height: "20px"}}
            type="button"
            icon='pi pi-pencil'
            className="p-button-sm p-button-text"
            onClick={() => {
                setRpType(getSnapshot(rowData));
                setEditorVisible(true)
            }}
        />;
    };

    const deleteTemplate = (rowData, options) => {
        return <ClearButtonOutlined
            style={{width: "20px", height: "20px"}}
            type="button"
            icon='pi pi-trash'
            severity="danger"
            className="p-button-sm p-button-text"
            onClick={() => {
                setRpType(getSnapshot(rowData));
                setShowDeleteDialog(true)
            }}
        />;
    };

    const deleteDialogFooterContent = (
        <div>
            <Button label="Да" icon="pi pi-check" severity="danger" onClick={() => {
                store.deleteItem(rpType)
                setShowDeleteDialog(false)
            }}/>
            <Button label="Нет" icon="pi pi-check" onClick={() => setShowDeleteDialog(false)} autoFocus/>
        </div>
    );

    return (
        <div className="page">
            <EditorDialog
                visible={editorVisible}
                setVisible={setEditorVisible}
                component={rpType}
                onSaveRpType={onSaveRpType}
            />

            <Dialog
                header="ВНИМАНИЕ"
                visible={showDeleteDialog}
                onHide={() => {
                    setShowDeleteDialog(false)
                }}
                footer={deleteDialogFooterContent}
            >
                <div>
                    Удалить тип действия:
                </div>
                <div>
                    <strong>{rpType?.name}?</strong>
                </div>
            </Dialog>

            <Header
                title="Тип действия"
                controlButtons={[
                    {
                        label: "Добавить",
                        action: () => {
                            setRpType({})
                            setEditorVisible(true)
                        },
                        visible: true
                    }
                ]}
            />
            <BlockUI blocking={store.isLoading}>
                <DataTable
                    value={store.items}
                    filters={filters}
                    filterDisplay="row"
                    selection={store.selected}
                    selectionMode="single"
                    onSelectionChange={e => {
                        if (e.value) store.setSelected(e.value);
                    }}
                    onFilter={onFilter}
                >
                    <Column filter header="Название" field="name"/>
                    <Column style={{flex: '0 0 4rem', width: "10px"}} body={editTemplate}></Column>
                    <Column style={{flex: '0 0 4rem', width: "10px"}} body={deleteTemplate}></Column>
                </DataTable>
                <Paginator
                    alwaysShow={false}
                    style={{justifyContent: "flex-end"}}
                    rows={params.rows}
                    first={params.first}
                    totalRecords={store.total}
                    onPageChange={onChangePage}
                />
            </BlockUI>
        </div>
    )
}

export default observer(RpType)
