import React, {useContext, useEffect} from 'react';
import {Switch} from "react-router-dom";

import RestrictedRoute from "../../../entyties/User/lib/RestrictedRoute";
import {AppContext} from "../../../app/AppContext";
import Users from "../../../entyties/User/ui/Users";
import RpList from "../../../entyties/Rp/ui/AdminRpList/RpList";
import Rp from "../../../entyties/Rp/ui/AdminRpList/Rp";
import Pharmacies from "../../../entyties/Pharmacy/ui/Pharmacies";
import useStore from "../../../hooks/useStore";
import User from "../../../entyties/User/ui/User";
import UserRoles from "../../../entyties/User/ui/UserRoles";
import Mkb10 from "../../../entyties/Mkb10/ui/Mkb10AdmList";
import Components from "../../../entyties/Component/ui/ComponentsList";
import LecForms from "../../../entyties/LecForm/ui/LecFormsAdmList";
import RpType from "../../../entyties/RpType/ui/AdminRpTypeList";
import LeftMenu from "../../../shared/ui/LeftMenu";
import ClientList from "../../../entyties/Clients/ui/ClientList";
import Org from "../../../entyties/Org/ui/Org";
import Orgs from "../../../entyties/Org/ui/Orgs";
import Pharmacy from "../../../entyties/Pharmacy/ui/Pharmacy";
import Role from "../../../entyties/User/ui/Role";
import RolePermissions from "../../../entyties/User/ui/RolePermissions";


function AppManager(props) {
    const context = useContext(AppContext)
    const store = useStore();

    const loadUserData = () =>{
        if (sessionStorage.getItem("refresh_token") !== undefined)

            context.apiService.getUser(sessionStorage.getItem("refresh_token"))
                .then(data => {
                    store.setUser(data)
                })
                .catch(e=>{console.log(e)})
    }

    useEffect(()=>{
        loadUserData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const menuContent = [
        {
            to: "/admin",
            title: "Сводка",
            icon: "pi-th-large",
            subMenu: [],
            deep: false
        },
        {
            to: "/admin/clients",
            title: "Клиенты",
            icon: "pi-briefcase",
            subMenu: [],
            deep: true
        },
        {
            to: "/admin/users",
            title: "Пользователи",
            icon: "pi-users",
            subMenu: [
                {
                    to: "/admin/users/users",
                    title: "Пользователи"
                },
                {
                    to: "/admin/users/roles",
                    title: "Роли"
                },
                {
                    to: "/admin/users/permissions",
                    title: "Модули и разрешения"
                },
            ],
            deep: true
        },
        {
            to: "/admin/org",
            title: "Медучреждения",
            icon: "pi-building",
            subMenu: [],
            deep: false
        },
        {
            to: "/admin/pharmacy",
            title: "Аптеки",
            icon: "pi-home",
            subMenu: [],
            deep: false
        },
        {
            to: "/admin/rp",
            title: "Прописи",
            icon: "pi-file",
            subMenu: [],
            deep: false
        },
        {
            to: "/reference/",
            title: "Справочники",
            icon: "pi-book",
            deep: true,
            subMenu: [
                {
                    to: "/admin/reference/mkb10",
                    title: "МКБ10"
                },
                {
                    to: "/admin/reference/components",
                    title: "Компоненты"
                },
                {
                    to: "/admin/reference/lec_forms",
                    title: "ЛФ"
                },
                {
                    to: "/admin/reference/rp-types",
                    title: "Типы действий"
                },
            ]
        },
        {
            to: "/",
            title: "Исследования",
            icon: "pi-share-alt",
            deep: false,
            subMenu: []
        },
        {
            to: "/",
            title: "Аналитика",
            icon: "pi-chart-bar",
            deep: false,
            subMenu: []
        },
    ];

    return (
        <div className="layout">
            <LeftMenu menuContent={menuContent}/>
            <div className="layout__container">
                <div className="layout__content">
                    <Switch>
                        <RestrictedRoute path="/admin/users/users" exact level={10}><Users/></RestrictedRoute>
                        <RestrictedRoute path="/admin/users/users/:id" exact level={10}>
                            <User style={{width: "100%"}}/>
                        </RestrictedRoute>
                        <RestrictedRoute path="/admin/org" exact level={10}><Orgs/></RestrictedRoute>
                        <RestrictedRoute path="/admin/org/:id" exact level={10}><Org/></RestrictedRoute>
                        <RestrictedRoute path="/admin/pharmacy" exact level={10}><Pharmacies/></RestrictedRoute>
                        <RestrictedRoute path="/admin/pharmacy/:id" exact level={10}><Pharmacy/></RestrictedRoute>
                        <RestrictedRoute path="/admin/rp" exact level={2}><RpList/></RestrictedRoute>
                        <RestrictedRoute path="/admin/rp/:id" exact level={2}><Rp/></RestrictedRoute>
                        <RestrictedRoute path="/admin/reference/mkb10" exact level={2}><Mkb10/></RestrictedRoute>
                        <RestrictedRoute path="/admin/reference/components" exact level={2}><Components/></RestrictedRoute>
                        <RestrictedRoute path="/admin/reference/lec_forms" exact level={2}><LecForms/></RestrictedRoute>
                        <RestrictedRoute path="/admin/reference/rp-types" exact level={2}><RpType/></RestrictedRoute>
                        <RestrictedRoute path="/admin/clients" exact level={2}><ClientList/></RestrictedRoute>
                        <RestrictedRoute path="/admin/users/permissions" exact level={2}><RolePermissions/></RestrictedRoute>
                        <RestrictedRoute path="/admin/users/permissions/:id" exact level={2}><RolePermissions/></RestrictedRoute>
                        <RestrictedRoute path="/admin/users/roles" exact level={2}><UserRoles/></RestrictedRoute>
                        <RestrictedRoute path="/admin/users/roles/:id" exact level={2}><Role/></RestrictedRoute>
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default AppManager;
