import {flow, types} from "mobx-state-tree"

import ApiService from "../shared/api/ApiService";
import {Ath} from "../entyties/Ath/model/Ath";
import {RpLecform} from "../entyties/LecForm/model/RpLecform";
import {RpType} from "../entyties/RpType/model/RpType";
import {Mkb10} from "../entyties/Mkb10/model/Mkb10";
import {Person} from "../entyties/Person/model/Person";
import {PrescriptionItem} from "../entyties/PrescriptionItem/model/PrescriptionItem";
import {Area} from "../entyties/Area/model/Area";


const Rp = types.model({
    id: types.maybe(types.integer),
    name: "",
    mds: "",
    action: "",
    recipe: types.maybeNull(types.string),
    ath: types.array(Ath),
    approved: types.maybeNull(types.boolean),
    lecForm: types.maybeNull(RpLecform),
    type: types.array(RpType),
    mkb10: types.array(Mkb10),
    author: types.maybeNull(Person),
    items: types.array(PrescriptionItem),
    price: types.maybeNull(types.number),
    areas: types.array(Area)
})

const RpStore = types.model("RpStore", {
    active: types.safeReference(Rp),
    rps: types.array(Rp),
    isLoading: types.maybe(types.boolean),
    total: types.maybe(types.integer),
}).actions(self => ({
    selectRpList: flow(function* (params) {
        self.isLoading = true;
        const res = yield ApiService.get("rp/getNames", params);
        self.rps = res.data.content === "" ? [] : res.data.content;
        self.total = res.data.totalElements;
        self.isLoading = false;
    }),
    selectRp(id) {
        self.active = id;
    },
    getRp: flow(function* (id) {
        const rp = yield ApiService.get(`rp/${id}`);
        console.log(rp)
        return rp.data;
    }),
    save: flow(function* (payload) {
        const rp = yield ApiService.post(`rp`, payload);
        return rp.data;
    })
}));

export default RpStore;